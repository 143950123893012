import React from 'react';
import { useParams } from 'react-router-dom';
import DecimalDisplay from '../../shared/decimal';
import { useNavigate } from 'react-router-dom';
import RentalType from '../../properties/rental-type';

const ManagersBuildingInfoTable = ({ managersBuildingInfoData }) => {
  const { managerId } = useParams();
  const navigate = useNavigate();
  return (
    <table className='flex flex-col w-full'>
      {/* head */}
      <thead className='w-full'>
        <tr className='grid grid-cols-8 text-left bg-[#E1E6EF]'>
          <th className='w-full py-3 pl-4 pr-2 text-[#6A6C70] text-xs font-medium inline-flex items-center'>
            PHONE
          </th>
          <th className='w-full py-3 pl-4 pr-2 text-[#6A6C70] text-xs font-medium inline-flex items-center'>
            ADDRESS
          </th>
          <th className='w-full py-3 pl-4 pr-2 text-[#6A6C70] text-xs font-medium inline-flex items-center'>
            NEIGHBORHOOD
          </th>
          <th className='w-full py-3 pl-4 pr-2 text-[#6A6C70] text-xs font-medium inline-flex items-center'>
            RENTAL TYPE
          </th>
          <th className='w-full py-3 pl-4 pr-2 text-[#6A6C70] text-xs font-medium inline-flex items-center'>
            MIN CREDIT
          </th>
          <th className='w-full py-3 pl-4 pr-2 text-[#6A6C70] text-xs font-medium inline-flex items-center'>
            MIN INCOME
          </th>
          <th className='w-full py-3 pl-4 pr-2 text-[#6A6C70] text-xs font-medium inline-flex items-center'>
            MON... RENT
          </th>
          <th className='w-full py-3 pl-4 pr-2 text-[#6A6C70] text-xs font-medium inline-flex items-center'>
            TERMI... FEE
          </th>
        </tr>
      </thead>

      {/* body */}
      <tbody className='w-full'>
        {managersBuildingInfoData.map((managerBuildingInfo, index) => (
          <tr
            key={index}
            onClick={() => {
              navigate(
                `/dashboard/managers/${managerId}/properties/${managerBuildingInfo.id}`
              );
            }}
            className='grid grid-cols-8 w-full border-b border-x border-[#E1E6EF] last:rounded-b-[10px] cursor-pointer'
          >
            {/* Name */}
            <td className='w-full py-3 text-sm pl-4 pr-2 flex items-center text-[#191923]'>
              {managerBuildingInfo?.buildingName}
            </td>

            {/* Address */}
            <td className='w-full py-3 pl-4 pr-2 flex items-center text-[#191923]'>
              <div className='inline-flex flex-col'>
                <p className='text-sm font-medium m-0'>
                  {managerBuildingInfo?.address?.street}
                </p>
                <p className='text-[#6A6C70] text-sm m-0'>
                  {managerBuildingInfo?.address?.city}
                </p>
              </div>
            </td>

            {/* Neighborhood */}
            <td className='w-full py-3 text-sm pl-4 pr-2 flex items-center text-[#191923]'>
              {managerBuildingInfo?.neighborhood}
            </td>

            {/* Rental Type */}
            <td className='w-full py-3 text-sm pl-4 pr-2 flex items-center text-[#191923]'>
              <RentalType
                size='sm'
                rentalType={managerBuildingInfo.rentalType}
              />
            </td>

            {/* Mininum Credit */}
            <td className='w-full py-3 text-sm pl-4 pr-2 flex items-center text-[#191923]'>
              {managerBuildingInfo?.minCreditScore}
            </td>

            {/* Minimum Income */}
            <td className='w-full py-3 text-sm pl-4 pr-2 flex items-center text-[#191923]'>
              <DecimalDisplay value={managerBuildingInfo?.minIncome} />
            </td>

            {/* Monthly Rent */}
            <td className='w-full py-3 text-sm pl-4 pr-2 flex items-center text-[#191923]'>
              <DecimalDisplay value={managerBuildingInfo?.monthlyRent} />
            </td>

            {/* Terminal Fee */}
            <td className='w-full py-3 text-sm pl-4 pr-2 flex items-center text-[#191923]'>
              <DecimalDisplay value={managerBuildingInfo?.terminationFee} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ManagersBuildingInfoTable;
