function Route(args) {
  if (!(this instanceof Route)) {
    return new Route(args);
  }

  console.log(args);

  if (!args) {
    throw new Error('You have to specify at least slug/method and handler');
  }

  const { method, ignoreVersionPrefix, slug, match, handler } = args || {};
  this._config = {};
  this._config.match = match;
  this._config.ignoreVersionPrefix =
    ignoreVersionPrefix === false ? false : true;
  this._config.method = method;
  this._config.slug = slug;

  if (!handler) {
    throw new Error('There is no handler function defined');
  }
  this._config.handler = handler;
}

Route.prototype.handle = async function (req) {
  return this._config.handler(req);
};

Route.prototype.match = function (req) {
  if (this._config.match) {
    return this._config.match(req);
  } else {
    return this._match(req);
  }
};

Route.prototype._match = function (req) {
  let pathToMatch;
  const url = new window.URL(req.url);
  pathToMatch = url.pathname;
  if (this._config.ignoreVersionPrefix) {
    // TODO shouldn't be hard coding a version like this
    pathToMatch = pathToMatch.replace('/api/v1', '');
  }
  return (
    pathToMatch === this._config.slug && this._config.method === req.method
  );
};

export default Route;
