const Table = ({ children, className }) => {
  return (
    <table className={`flex flex-col w-full ${className}`}>
      {children}
    </table>
  )
}

const HeaderRow = ({ isRounded, children, gridLayout = 'grid-cols-8', className }) => {
  return (
    <thead className='w-full'>
      <tr className={`w-full grid ${gridLayout} text-left ${isRounded && 'first:rounded-t-md'} bg-[#E1E6EF] ${className}`}>
        {children}
      </tr>
    </thead>
  )
}

const HeaderCell = ({ children, sortColumn = false, className }) => {
  return (
    <th className={`flex items-center justify-start w-full text-[#191923] text-sm font-semibold tracking-wide uppercase ${sortColumn ? 'py-1 px-2' : 'py-1 px-4'} ${className}`}>
      {children}
    </th>
  )
}

const TableBody = ({ children, className }) => {
  return (
    <tbody className={`w-full ${className}`}>
      {children}
    </tbody>
  )
}

const BodyRow = ({ children, key, onClick, gridLayout = 'grid-cols-8', className }) => {
  return (
    <tr
      key={key}
      onClick={onClick}
      className={`grid cursor-pointer ${gridLayout} w-full border-b border-x border-slate last:rounded-b-md ${className} ${onClick && 'hover:bg-slate-100'}`}
    >
      {children}
    </tr>
  )
}

const TableCell = ({ children, top, bottom, className, onClick }) => {
  return (
    <td className={`w-full py-2 px-4 flex gap-2 items-center text-[$191923] text-sm truncate ... ${className}`} onClick={onClick}>
      {children}
      {top && bottom &&
        <div className='flex flex-col'>
          <div className='uppercase tracking-wider'>
            {top}
          </div>
          <div className='text-[#6A6C70] m-0'>
            {bottom}
          </div>
        </div>
      }
    </td>
  )
}

export {Table, HeaderRow, HeaderCell, TableBody, BodyRow, TableCell}
