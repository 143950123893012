import { useState } from 'react';
import { toast } from 'react-toastify';
import ErrorData from '../../../components/shared/error-data';
import LoadingSpinner from '../../../components/shared/loading-spinner';
import PlansTable from '../../../components/plans/plans-table';
import DeleteModal from '../../../components/shared/delete-modal';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { Link, useParams } from 'react-router-dom';
import ManagersBuildingInfoTable from '../../../components/managers/managers-building-info-table';
import EmptyData from '../../../components/shared/empty-data';
import DashboardWrapperLayout from '../../../layouts/dashboard-wrapper';
import { isEmpty } from '../../../utils/isEmpty';
import {
  useGetManagerQuery,
  useDeleteManagerMutation,
} from '../../../redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import StatusLabel from '../../../components/managers/contract-status-label';
import Button from 'common/components/button';

const ManagerDetails = () => {
  const { managerId } = useParams();
  const navigate = useNavigate();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [
    deleteManager,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useDeleteManagerMutation();

  const {
    isError: isGetError,
    error: getError,
    data: managerData,
  } = useGetManagerQuery(managerId);

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  // TODO handle no data condition better
  if (!managerData) return <LoadingSpinner />;

  console.log(managerData);

  const { streetAddress1, city, state, country } = managerData?.address || {
    streetAddress1: '',
    city: '',
    state: '',
    country: '',
  };

  return (
    <DashboardWrapperLayout title='Managers'>
      {(isUpdateLoading || isUpdateFetching) && <LoadingSpinner />}
      {deleteModalOpen && (
        <DeleteModal
          handleDelete={() => {
            deleteManager({ id: managerId })
              .unwrap()
              .then(() => {
                navigate('/dashboard/managers');
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Delete Failed');
              });
          }}
          onCancel={() => {
            setDeleteModalOpen(false);
          }}
        />
      )}
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Dashboard
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link
            to='/dashboard/managers'
            className='text-[#CED0CE] text-base font-normal'
          >
            Property Managers{' '}
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link to='' className='text-[#191923] text-base font-normal'>
            Details{' '}
          </Link>
        </div>

        {/* Manager Details Table */}
        <div className='w-full border rounded-[10px] gap-5'>
          {/* header */}
          <div className='flex w-full items-center bg-[#E1E6EF] text-[#191923] text-base font-bold px-5 py-5 rounded-t-[10px]'>
            {`Manager's Information`}
          </div>

          {/* body content */}
          <div className='flex w-full flex-col gap-6'>
            {/* details */}
            <div className='grid w-full grid-cols-4 gap-y-10 gap-x-6 p-5'>
              {/* name */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>Name:</p>

                <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                  {managerData?.name}
                </p>
              </div>

              {/* phone */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>Phone:</p>

                <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                  {managerData?.phone}
                </p>
              </div>

              {/* Email */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>Email:</p>

                <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                  {managerData?.email}
                </p>
              </div>

              {/* address */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>Address:</p>

                <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                  {streetAddress1}
                  <span className='text-[#6A6C70] font-normal text-sm'>
                    {`${city}, ${state} ${country}`}
                  </span>
                </p>
              </div>

              {/* Duration of Contract */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>
                  Duration of Contract:
                </p>

                <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                  {managerData.durationOfContract} Months
                </p>
              </div>

              {/* status */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>Status:</p>

                <StatusLabel status={managerData.contractStatus} size='base' />
              </div>
            </div>

            {/* buttons */}
            <div className='w-full flex justify-end items-center pb-5 pr-3'>
              <div className='flex items-center gap-5'>
                <Button
                  onClick={() => {
                    navigate(`/dashboard/managers/${managerId}/edit`);
                  }}
                  Icon={BiEdit}
                  title='Edit'
                />
                <Button
                  onClick={() => {
                    setDeleteModalOpen(true);
                  }}
                  Icon={AiOutlineDelete}
                  theme='secondary'
                  title='Delete'
                />
              </div>
            </div>
          </div>
        </div>

        {/* Manager's Plan Table */}
        <div className='w-full rounded-t-[10px] gap-5'>
          {/* header */}
          <div className='flex w-full items-center bg-[#E1E6EF] text-[#191923] text-base font-bold px-5 py-5 rounded-t-[10px]'>
            Plans
          </div>

          {/* body content */}
          {/* plans */}
          <div className='flex w-full flex-col gap-6'>
            {/* Manager Plan table */}
            {isEmpty(managerData?.plans) ? (
              <EmptyData />
            ) : (
              <PlansTable IsRounded={false} plans={managerData?.plans} />
            )}
          </div>
        </div>

        <div className='flex'>
          <div className='min-width-[100px] max-width-[200px]'>
            <Button
              onClick={() => {
                navigate(
                  `/dashboard/managers/${managerData.id}/properties/new`
                );
              }}
              title='New Building'
            />
          </div>
        </div>
        <div className='w-full rounded-t-[10px] gap-5'>
          {/* header */}
          <div className='flex w-full items-center bg-[#E1E6EF] text-[#191923] text-base font-bold px-5 py-5 rounded-t-[10px]'>
            Properties
          </div>
          <div className='flex w-full flex-col gap-6'>
            {isEmpty(managerData?.properties) ? (
              <EmptyData />
            ) : (
              <ManagersBuildingInfoTable
                managersBuildingInfoData={managerData?.properties}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardWrapperLayout>
  );
};

export default ManagerDetails;
