import React from 'react';
import Button from 'common/components/button';

const CopyTextComponent = ({ text, copyText, className }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyText || text).then(
      () => {

      },
      (err) => {
        console.error('Failed to copy text: ', err);
      }
    );
  };

  return (
    <Button onClick={copyToClipboard} className={`px-6 ${className}`} wFull={false} title={text} />
  );
};

export default CopyTextComponent;