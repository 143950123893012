import React from 'react';
import { FaBuildingShield, FaPeopleLine } from 'react-icons/fa6';
import { IoIosSend } from 'react-icons/io';
import { RiErrorWarningFill } from 'react-icons/ri';
import DashboardCard from '../../../components/shared/dashboard-card';
import DashboardWrapperLayout from '../../../layouts/dashboard-wrapper';
import { useGetAdminSummaryQuery } from '../../../redux/api/ciosAdminApi/ciosAdminApi';

const Home = () => {
  const { data: adminSummary } = useGetAdminSummaryQuery();

  return (
    <DashboardWrapperLayout title='Dashboard'>
      <div className='w-full grid grid-cols-4 gap-8'>
        {/* cards */}
        <DashboardCard
          title='Claims'
          count={adminSummary?.claims || 0}
          path='/dashboard/claims'
          Icon={RiErrorWarningFill}
        />
        <DashboardCard
          title='Plans'
          count={adminSummary?.plans || 0}
          path='/dashboard/plans'
          Icon={IoIosSend}
        />
        <DashboardCard
          title='Members'
          count={0}
          path='/dashboard/members'
          Icon={FaPeopleLine}
        />
        <DashboardCard
          title='Managers'
          count={0}
          path='/dashboard/managers'
          Icon={FaBuildingShield}
        />
        <DashboardCard
          title='Owners'
          count={0}
          path='/dashboard/owners'
          Icon={FaBuildingShield}
        />
      </div>
    </DashboardWrapperLayout>
  );
};

export default Home;
