const InfoCard = ({ title, children, className }) => {
  return (
    <div
      className={`w-full rounded-md border border-solid gap-4 border-slate-200 ${className}`}
    >
      {/* header */}
      <div className="flex w-full items-center bg-slate-200 text-[#191923] text-lg font-semibold px-4 py-3 rounded-t-md">
        {title}
      </div>

      {/* body content */}
      <div className="flex w-full flex-col divide-y">{children}</div>
    </div>
  );
};

const InfoGrid = ({ children, className }) => {
  return (
    <div
      className={`grid w-full grid-cols-4 mobile:grid-cols-2 gap-4 p-4 ${className}`}
    >
      {children}
    </div>
  );
};

const InfoField = ({ title, children, className }) => {
  return (
    <div className={`flex flex-col gap-1 ${className}`}>
      <label className="text-xs text-[#191923] tracking-wider uppercase font-semibold">
        {title}
      </label>

      <div className="text-[#191923]">{children}</div>
    </div>
  );
};

export { InfoCard, InfoGrid, InfoField };
