export const localeTimeOptions = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

export const shortTime = new Intl.DateTimeFormat('en', {
  timeStyle: 'short',
});

export function toDDMMYYYY(date) {
  const formatter = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  let _date = date;
  if (typeof date === 'string') {
    _date = new Date(date);
  }

  return formatter.format(_date);
}

export function toDatePickerFormat(date) {
  if (!date) {
    return '';
  }
  if (typeof date === 'string') {
    try {
      return date.split('T')[0];
    } catch (e) {
      return '';
    }
  } else {
    const formatter = new Intl.DateTimeFormat('en-CA', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return formatter.format(date);
  }
  console.log(date);
}
