import React from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { Link } from 'react-router-dom';

const DashboardCard = ({ Icon, count, path = '', title = '' }) => {
  return (
    <Link className='h-full w-full' to={path}>
      <div className='flex border border-[#6A6C70] rounded-[10px] px-3 py-5 flex-col gap-6 items-center h-full'>
        <div className='flex flex-col gap-2 items-center text-center'>
          <Icon className='text-gray-200 text-4xl' />
          <span className='text-2xl text-[HiArrowRight] font-bold'>
            {title}
          </span>
          {count ? (
            <span className='bg-[#E1E6EF] rounded-[10px] inline-flex items-center justify-center text-xs font-medium text-[#6A6C70] py-1 px-[10px]'>
              {count}
            </span>
          ) : null}
        </div>

        <div className='flex justify-between gap-2 items-center mt-auto'>
          <span className='text-base font-semibold text-[HiArrowRight]'>
            See all
          </span>
          <HiArrowRight className='text-[HiArrowRight] text-base' />
        </div>
      </div>
    </Link>
  );
};

export default DashboardCard;
