import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import StatusLabel from '../contract-status-label';
import DeleteModal from '../../shared/delete-modal';
import {
  useDeleteManagerMutation,
  useUpdatePlanMutation,
} from '../../../redux/api/ciosAdminApi/ciosAdminApi';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectRequestManagerForPlan,
  resetManagerForPlan,
} from '../../../redux/features/misc/miscSlice';
import LoadingSpinner from '../../../components/shared/loading-spinner';

const TableDataRow = ({ name, phone, duration, status, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const requestManagerForPlan = useSelector(selectRequestManagerForPlan);
  const [updatePlan, { isLoading: updatePlanLoading }] =
    useUpdatePlanMutation();
  const planIdForRequestManager = requestManagerForPlan;
  const hasPlan = planIdForRequestManager ? true : false;
  const [deleteManager, { isLoading: deleteManagerLoading }] =
    useDeleteManagerMutation();
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });

  const isLoading = deleteManagerLoading || updatePlanLoading;

  // STATE
  const [isMenuOpen, setMenuState] = useState(false);

  // HANDLERS
  const toggleMenu = () => {
    setMenuState(!isMenuOpen);
  };

  return (
    <tr
      className={`grid grid-cols-5 w-full border-b border-x border-[#E1E6EF] last:rounded-b-[10px] z-10 cursor-pointer`}
      onClick={() => {
        if (hasPlan) {
          updatePlan({
            id: planIdForRequestManager,
            managerId: id,
          })
            .unwrap()
            .then(() => {
              dispatch(resetManagerForPlan());
              navigate(`/dashboard/plans/${planIdForRequestManager}`);
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Delete Failed');
            });
        } else {
          navigate(`/dashboard/managers/${id}`);
        }
      }}
    >
      {/* profile */}
      <td className='w-full py-3 px-6 flex items-center text-[#191923]'>
        {hasPlan && (
          <span
            className={`relative -left-5 animate-ping h-[12px] w-[12px] bg-green-400 rounded-[50%]`}
          />
        )}
        <div className='inline-flex flex-col'>
          <p className='text-sm font-medium m-0'>{name}</p>
        </div>
      </td>

      {/* Phone */}
      <td className='w-full py-3 px-6 flex items-center text-[#191923]'>
        <p className='text-sm m-0'>{phone}</p>
      </td>

      {/* Duration */}
      <td className='w-full py-3 text-sm px-6 flex items-center text-[#191923]'>
        {duration}
      </td>

      {/* Status and Edit button */}
      <td className='w-full py-3 px-6 flex items-center relative'>
        <div className=' inline-flex justify-between w-full items-center'>
          {/* Status */}
          <StatusLabel size='sm' status={status} />
        </div>

        {/* Delete Modal Handling */}
        {deleteModal.open && (
          <DeleteModal
            handleDelete={(e) => {
              e.stopPropagation();
              deleteManager({ id: deleteModal.id })
                .unwrap()
                .then(() => {
                  setDeleteModal({ open: false, id: null });
                  navigate('/dashboard/managers');
                })
                .catch((e) => {
                  toast.error(e?.data?.msg || 'Update Failed');
                });
            }}
            onCancel={(e) => {
              e.stopPropagation();
              setDeleteModal({ open: false, id: null });
            }}
          />
        )}

        {/* Loading Indicator logic when api requesting */}
        {isLoading && <LoadingSpinner />}
      </td>
      <td>
        <div className=' inline-flex w-full justify-end gap-5 items-center'>
          {/* Edit button */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/dashboard/managers/${id}/edit`);
            }}
            className='inline-flex gap-2 items-center rounded-3xl transition-all px-3 py-2 hover:bg-gray-100'
          >
            <BiEdit className='text-[#24242E] text-xl' />
            <span className='text-base text-[#24242E]'>Edit</span>
          </button>

          {/* Delete button */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              setDeleteModal({ open: true, id });
            }}
            className='inline-flex gap-2 items-center rounded-3xl transition-all px-3 py-2 hover:bg-gray-100'
          >
            <AiOutlineDelete className='text-[#24242E] text-xl' />
            <span className='text-base text-[#24242E]'>Delete</span>
          </button>
        </div>
      </td>
    </tr>
  );
};

export default TableDataRow;
