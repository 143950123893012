import React from 'react';
import TextArea from '../../../components/shared/text-area-input';
import { toast } from 'react-toastify';
import ErrorData from '../../../components/shared/error-data';
import LoadingSpinner from '../../../components/shared/loading-spinner';
import { Formik } from 'formik';
import { toDDMMYYYY } from '../../../utils/date';
import { IoMdSave } from 'react-icons/io';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Button from 'common/components/button';
import DocumentPreview from '../../../components/shared/document-preview';
import SelectInput from '../../../components/shared/select-input';
import StatusLabel from '../../../components/claims/status-label';
import ClaimType from '../../../components/claims/claim-type';
import DashboardWrapperLayout from '../../../layouts/dashboard-wrapper';
import {
  useGetClaimQuery,
  useUpdateClaimMutation,
} from '../../../redux/api/ciosAdminApi/ciosAdminApi';
import { claimStatusEnum, claimTypeEnum } from '../../../utils/enums';

const claimStatusOptions = [
  { label: 'Draft', value: claimStatusEnum.Draft },
  { label: 'Submitted', value: claimStatusEnum.Submitted },
  { label: 'Completed', value: claimStatusEnum.Completed },
  { label: 'In Review', value: claimStatusEnum.Review },
  { label: 'Expired', value: claimStatusEnum.Expired },
  { label: 'Denied', value: claimStatusEnum.Denied },
  { label: 'Approved', value: claimStatusEnum.Approved },
  { label: 'Confirmed', value: claimStatusEnum.Confirmed },
];

function OtherView({ claim }) {
  return (
    <>
      <div className='flex flex-col col-span-4 gap-5'>
        <p className='text-xs text-[#191923] font-medium'>Description:</p>

        <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
          {claim.otherClaim.description}
        </p>
      </div>
    </>
  );
}

function NewHomeView({ claim }) {
  const { homeAddress, offerDate, proposedStart } = claim.purchasedHome;
  const { streetAddress1, city, state, country } = homeAddress || {
    streetAddress1: '',
    city: '',
    state: '',
    country: '',
  };
  return (
    <>
      {/* Proposed Start */}
      <div className='flex flex-col gap-5'>
        <p className='text-xs text-[#191923] font-medium'>Proposed Start</p>

        <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
          {proposedStart ? toDDMMYYYY(new Date(proposedStart)) : ''}
        </p>
      </div>

      {/* Offer Date */}
      <div className='flex flex-col gap-5'>
        <p className='text-xs text-[#191923] font-medium'>Offer Date</p>

        <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
          {offerDate ? toDDMMYYYY(new Date(offerDate)) : ''}
        </p>
      </div>

      {/* address */}
      <div className='flex flex-col gap-5'>
        <p className='text-xs text-[#191923] font-medium'>Address:</p>

        <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
          {streetAddress1}
          <span className='text-[#6A6C70] font-normal text-sm'>
            {`${city}, ${state} ${country}`}
          </span>
        </p>
      </div>
    </>
  );
}

function NewJobView({ claim }) {
  const {
    offerDate,
    jobTitle,
    companyName,
    contactName,
    contactEmail,
    contactPhone,
    proposedStart,
  } = claim.newJob;

  console.log('Claim data?', claim.newJob);
  return (
    <>
      <div className='flex flex-col gap-5'>
        <p className='text-xs text-[#191923] font-medium'>Contact Name:</p>

        <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
          {contactName}
          <span className='text-[#6A6C70] font-normal text-sm'>
            {contactEmail}
          </span>
        </p>
      </div>

      {/* Contact Phone */}
      <div className='flex flex-col gap-5'>
        <p className='text-xs text-[#191923] font-medium'>Contact Phone:</p>

        <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
          {contactPhone}
        </p>
      </div>

      {/* Proposed Start */}
      <div className='flex flex-col gap-5'>
        <p className='text-xs text-[#191923] font-medium'>Proposed Start</p>

        <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
          {proposedStart ? toDDMMYYYY(new Date(proposedStart)) : ''}
        </p>
      </div>

      {/* Offer Date */}
      <div className='flex flex-col gap-5'>
        <p className='text-xs text-[#191923] font-medium'>Offer Date</p>

        <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
          {offerDate ? toDDMMYYYY(new Date(offerDate)) : ''}
        </p>
      </div>

      {/* Job Title */}
      <div className='flex flex-col gap-5'>
        <p className='text-xs text-[#191923] font-medium'>Job Title:</p>

        <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
          {jobTitle}
        </p>
      </div>

      {/* Company Name */}
      <div className='flex flex-col gap-5'>
        <p className='text-xs text-[#191923] font-medium'>Company Name:</p>

        <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
          {companyName}
        </p>
      </div>
    </>
  );
}

const ClaimDetails = () => {
  const navigate = useNavigate();
  const { claimId } = useParams();

  const [
    updateClaim,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useUpdateClaimMutation();
  const {
    data: claimData,
    isError: isGetError,
    error: getError,
  } = useGetClaimQuery(claimId);

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  console.log('Claim Data: ', claimData);
  // TODO Have loading screen, or something better if no data available
  if (!claimData) {
    return <LoadingSpinner />;
  }

  const {
    userId: userData,
    planId: planData,
    claimType,
    name: claimName,
  } = claimData;
  const { id: planId } = planData;
  const leaseData = planData.lease;
  const addressData = leaseData?.address;
  const { streetAddress1, city, state, country } = addressData || {
    streetAddress1: '',
    city: '',
    state: '',
    country: '',
  };

  const { startDate: startOfLease, endDate: endOfLease } = leaseData;

  const {
    submissionAt,
    moveOutDate,
    status: claimStatus,
    deniedFeedback,
  } = claimData;

  return (
    <Formik
      onSubmit={(value) => {
        updateClaim({
          id: claimData.id,
          ...value,
        })
          .unwrap()
          .catch((e) => {
            toast.error(e?.data?.msg || 'Update Failed');
          });
      }}
      initialValues={{
        status: claimStatus,
        deniedFeedback,
      }}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        isValid,
        values,
        setFieldValue,
      }) => {
        return (
          <DashboardWrapperLayout title='Claims'>
            {(isUpdateLoading || isUpdateFetching) && (
              <LoadingSpinner center={true} />
            )}
            <div className='grid grid-cols-4 gap-6'>
              <div className='col-span-3 '>
                <div className='flex flex-col w-full gap-6'>
                  {/* breadcrumb */}
                  <div className='flex gap-3 items-center'>
                    <Link
                      to='/dashboard/home'
                      className='font-normal text-base text-[#CED0CE]'
                    >
                      Dashboard
                    </Link>
                    <span className='font-normal text-base text-[#CED0CE]'>
                      {'>'}
                    </span>
                    <Link
                      to='/dashboard/claims'
                      className='text-[#CED0CE] text-base font-normal'
                    >
                      Claims{' '}
                    </Link>
                    <span className='font-normal text-base text-[#CED0CE]'>
                      {'>'}
                    </span>
                    <Link
                      to=''
                      className='text-[#191923] text-base font-normal'
                    >
                      {claimData.name || 'Details'}
                    </Link>
                  </div>

                  {/* User Details Table */}
                  <div className='w-full rounded-[10px] gap-5 border border-gray'>
                    {/* header */}
                    <div className='flex w-full items-center bg-[#E1E6EF] rounded-t-[10px] text-[#191923] text-base font-bold px-5 py-5'>
                      User Details
                    </div>

                    {/* body content */}
                    <div className='flex w-full flex-col gap-6'>
                      {/* details */}
                      <div className='grid w-full grid-cols-4 gap-y-10 gap-x-6 p-5'>
                        {/* name */}
                        <div
                          onClick={() => {
                            navigate(`/dashboard/members/${userData.id}`);
                          }}
                          className='flex flex-col gap-5 cursor-pointer'
                        >
                          <p className='text-xs text-[#191923] font-medium'>
                            Name:
                          </p>

                          <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                            {userData.name}
                            <span className='text-[#6A6C70] font-normal text-sm'>
                              {userData.email}
                            </span>
                          </p>
                        </div>

                        {/* address */}
                        <div className='flex flex-col gap-5'>
                          <p className='text-xs text-[#191923] font-medium'>
                            Address:
                          </p>

                          <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                            {streetAddress1}
                            <span className='text-[#6A6C70] font-normal text-sm'>
                              {`${city}, ${state} ${country}`}
                            </span>
                          </p>
                        </div>

                        {/* Contact Phone */}
                        <div className='flex flex-col gap-5'>
                          <p className='text-xs text-[#191923] font-medium'>
                            User Phone:
                          </p>

                          <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                            {userData.phone}
                          </p>
                        </div>

                        {/* Submission At */}
                        <div className='flex flex-col gap-5'>
                          <p className='text-xs text-[#191923] font-medium'>
                            Submission At
                          </p>

                          <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                            {submissionAt
                              ? toDDMMYYYY(new Date(submissionAt))
                              : ''}
                          </p>
                        </div>

                        {/* Lease Start */}
                        <div className='flex flex-col gap-5'>
                          <p className='text-xs text-[#191923] font-medium'>
                            Coverage/Lease Start
                          </p>

                          <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                            {startOfLease
                              ? toDDMMYYYY(new Date(startOfLease))
                              : ''}
                          </p>
                        </div>

                        {/* Lease End */}
                        <div className='flex flex-col gap-5'>
                          <p className='text-xs text-[#191923] font-medium'>
                            Coverage/Lease End
                          </p>

                          <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                            {endOfLease ? toDDMMYYYY(new Date(endOfLease)) : ''}
                          </p>
                        </div>

                        {/* Move Out Date */}
                        <div className='flex flex-col gap-5'>
                          <p className='text-xs text-[#191923] font-medium'>
                            Move Out Date
                          </p>

                          <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                            {moveOutDate
                              ? toDDMMYYYY(new Date(moveOutDate))
                              : ''}
                          </p>
                        </div>

                        {/* Type */}
                        <div className='flex flex-col gap-5'>
                          <p className='text-xs text-[#191923] font-medium'>
                            Type:
                          </p>

                          <div className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                            {<ClaimType status={claimType} />}
                          </div>
                        </div>

                        {/* Type Dependent View */}
                        {claimType === claimTypeEnum.NEW_JOB && (
                          <NewJobView claim={claimData} />
                        )}
                        {claimType === claimTypeEnum.PURCHASED_HOME && (
                          <NewHomeView claim={claimData} />
                        )}
                        {claimType === claimTypeEnum.OTHER_CLAIM && (
                          <OtherView claim={claimData} />
                        )}
                      </div>

                      {/* SUBMITTED DOCUMENT */}
                      <div className='flex flex-col col-span-4 w-full gap-[10px] px-5 pb-5'>
                        <span className='text-xs text-[#191923] font-medium'>
                          Submitted Document
                        </span>

                        {/* uplaaded file */}
                        <DocumentPreview
                          url={
                            claimData?.otherClaim?.supportingDocUrl ||
                            claimData?.newJob?.offerPdfUrl ||
                            claimData?.purchasedHOme?.offerPdfUrl
                          }
                          label={
                            claimType === 'other'
                              ? 'SupportingDoc.pdf'
                              : 'Offer_letter.pdf'
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='pt-[46px]'>
                {/* Admin Tools Table */}
                <div className='w-full rounded-[10px] gap-5'>
                  {/* header */}
                  <div className='flex w-full items-center bg-[#E1E6EF] text-[#191923] text-base font-bold px-5 py-5 rounded-[10px]'>
                    Admin tool
                  </div>

                  {/* body content */}
                  <div className='flex w-full flex-col gap-6 p-5'>
                    {/* status */}
                    <div className='flex flex-col gap-5'>
                      <p className='text-xs text-[#191923] font-medium'>
                        Status:
                      </p>

                      <StatusLabel status={values.status} size='sm' />
                    </div>

                    {/* Plan ID */}
                    <div
                      onClick={() => {
                        navigate(`/dashboard/plans/${planId}`);
                      }}
                      className='flex flex-col gap-5 cursor-pointer'
                    >
                      <p className='text-xs text-[#191923] font-medium'>
                        Plan ID:
                      </p>

                      <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold break-words'>
                        {planId}
                      </p>
                    </div>

                    {/* Claim ID */}
                    <div className='flex flex-col gap-5'>
                      <p className='text-xs text-[#191923] font-medium'>
                        Claim ID:
                      </p>

                      <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold  break-words'>
                        {claimId}
                      </p>
                      <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold  break-words'>
                        {claimName}
                      </p>
                    </div>

                    {/* Claim Status */}
                    <div className='flex flex-col gap-2'>
                      <label className='text-[#191923] text-sm font-bold'>
                        Change status
                      </label>

                      <SelectInput
                        options={claimStatusOptions}
                        placeholder='Select category'
                        name='values.status'
                        value={claimStatusOptions.find(
                          (status) => status.value === values.status
                        )}
                        onChange={(selectedOption) => {
                          setFieldValue('status', selectedOption?.value || '');
                        }}
                        onBlur={handleBlur}
                      />
                    </div>

                    {values.status === claimStatusEnum.Denied && (
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Rejection Reason
                          </label>
                          <TextArea
                            placeholderText='Rejection Reason'
                            value={values.deniedFeedback}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='deniedFeedback'
                          />
                        </div>
                      </div>
                    )}

                    <div className='flex'>
                      <div className='min-width-[100px] max-width-[200px]'>
                        {/* Buttons */}
                        <Button
                          onClick={handleSubmit}
                          Icon={IoMdSave}
                          title='Save Changes'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DashboardWrapperLayout>
        );
      }}
    </Formik>
  );
};

export default ClaimDetails;
