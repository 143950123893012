import React from 'react';

const CheckBox = ({
  checked,
  value,
  onBlur,
  onFocus,
  name,
  onChange,
  disabled,
  required,
}) => {
  return (
    <div className='checkbox-container'>
      <label className='lb-container'>
        <input
          disabled={disabled}
          type='checkbox'
          name={name}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          checked={checked}
          onChange={onChange}
          required={required}
        />
      </label>
    </div>
  );
};

export default CheckBox;
