import { useState } from 'react';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../../components/shared/loading-spinner';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useDeleteOwnerMutation } from '../../../redux/api/ciosAdminApi/ciosAdminApi';
import DeleteModal from '../../shared/delete-modal';
import StatusLabel from '../../../components/owners/status-label';

const OwnersTable = ({ owners }) => {
  // DATA INITIALIZATION
  const navigate = useNavigate();
  const [
    deleteOwner,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useDeleteOwnerMutation();
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });

  return (
    <table className='flex flex-col w-full'>
      {(isUpdateLoading || isUpdateFetching) && <LoadingSpinner />}
      {deleteModal.open && (
        <DeleteModal
          handleDelete={() => {
            deleteOwner({ id: deleteModal.id })
              .unwrap()
              .then(() => {
                setDeleteModal({ open: false, id: null });
                navigate('/dashboard/owners');
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Delete Failed');
              });
          }}
          onCancel={() => {
            setDeleteModal({ open: false, id: null });
          }}
        />
      )}

      {/* head */}
      <thead className='w-full'>
        <tr className='grid grid-cols-4 text-left first:rounded-t-[10px] bg-[#E1E6EF]'>
          <th className='w-full py-3 px-6 text-[#6A6C70] text-xs font-medium'>
            NAME
          </th>
          <th className='w-full py-3 px-6 text-[#6A6C70] text-xs font-medium'>
            ADDRESS
          </th>
          <th className='w-full py-3 px-6 text-[#6A6C70] text-xs font-medium'>
            STATUS
          </th>
        </tr>
      </thead>

      {/* body */}
      <tbody className='w-full'>
        {owners?.map((owner, index) => (
          <tr
            key={index}
            onClick={() => {
              navigate(`/dashboard/owners/${owner.id}`);
            }}
            className='grid grid-cols-4 w-full border-b border-x border-[#E1E6EF] last:rounded-b-[10px] cursor-pointer'
          >
            {/* Name */}
            <td className='w-full py-3 px-6 flex items-center text-[#191923]'>
              <p className='text-sm font-medium m-0'>{owner?.name}</p>
            </td>

            {/* Email */}
            <td className='w-full py-3 text-sm px-6 flex items-center text-[#191923]'>
              {owner?.email}
            </td>

            <td className='w-full py-3 text-sm px-6 flex items-center text-[#191923]'>
              <StatusLabel status={owner.status} size='sm' />
            </td>

            {/* Status and Edit button */}
            <td className='w-full py-3 px-6 flex items-center'>
              <div className=' inline-flex w-full justify-end gap-5 items-center'>
                {/* Edit button */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/dashboard/owners/${owner?.id}/edit`);
                  }}
                  className='inline-flex gap-2 items-center rounded-3xl transition-all px-3 py-2 hover:bg-gray-100'
                >
                  <BiEdit className='text-[#24242E] text-xl' />
                  <span className='text-base text-[#24242E]'>Edit</span>
                </button>

                {/* Delete button */}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteModal({ open: true, id: owner.id });
                  }}
                  className='inline-flex gap-2 items-center rounded-3xl transition-all px-3 py-2 hover:bg-gray-100'
                >
                  <AiOutlineDelete className='text-[#24242E] text-xl' />
                  <span className='text-base text-[#24242E]'>Delete</span>
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OwnersTable;
