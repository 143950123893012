import { combineReducers } from '@reduxjs/toolkit';
import { ciosAdminApi } from '../api/ciosAdminApi/ciosAdminApi';
import { authSlice } from './auth/authSlice';
import { miscSlice } from './misc/miscSlice';

export const rootReducer = combineReducers({
  [authSlice.name]: authSlice.reducer,
  [miscSlice.name]: miscSlice.reducer,
  [ciosAdminApi.reducerPath]: ciosAdminApi.reducer,
});
