import React from 'react';
import { RotatingLines } from 'react-loader-spinner';

const LoadingSpinner = ({
  heightTailwind = 'h-[50vh]',
  spinnerColor = '#191923',
  center = false,
}) => {
  const containerClass = center
    ? 'fixed z-[100] inset-0 flex items-center justify-center'
    : `w-full z-[100] ${heightTailwind} flex items-center justify-center`;
  return (
    <div className={containerClass}>
      <RotatingLines
        strokeColor={spinnerColor}
        strokeWidth='4'
        animationDuration='0.75'
        width='40'
        visible={true}
      />
    </div>
  );
};

export default LoadingSpinner;
