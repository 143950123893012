import { useState } from 'react';
import { IoMdSave, IoMdAdd, IoMdRemove } from 'react-icons/io';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import Input from '../../../components/shared/input';
import SelectInput from '../../../components/shared/select-input';
import AutoCompleteAddress from '../../../components/shared/autoaddress';
import DashboardWrapperLayout from '../../../layouts/dashboard-wrapper';
import { Form, Formik, FieldArray, useFormikContext } from 'formik';
import deepGet from '../../../utils/deepGet';
import { rentalTypeEnum } from '../../../utils/enums';
import { useNewManagersMutation } from '../../../redux/api/ciosAdminApi/ciosAdminApi';
import FormError from '../../../components/shared/form-error';
import { PHONE_REGEX } from '../../../utils/regex';
import { useNavigate } from 'react-router-dom';
import Button from 'common/components/button';

const buildingSchema = Yup.object().shape({
  buildingName: Yup.string().required('Building name is required'),
  minCreditScore: Yup.number()
    .required('Minimum credit score is required')
    .min(300, 'Minimum credit score is 300')
    .max(1000, 'Maximum credit score is 1000'),
  minIncome: Yup.number()
    .required('Minimum income is required')
    .min(0, 'Minimum income must be a non-negative number'),
  neighborhood: Yup.string().required('Neighborhood is required'),
  rentalType: Yup.string().required('Rental type is required'),
  monthlyRent: Yup.number()
    .required('Monthly rent is required')
    .min(0, 'Monthly rent must be a non-negative number'),
  terminationFee: Yup.number()
    .required('Termination fee is required')
    .min(0, 'Termination fee must be a non-negative number'),
});

const rentalTypeOptions = [
  { label: 'Apartment', value: rentalTypeEnum.APARTMENT },
  { label: 'Condo', value: rentalTypeEnum.CONDO },
  { label: 'House', value: rentalTypeEnum.HOUSE },
  { label: 'Townhouse', value: rentalTypeEnum.TOWNHOUSE },
  { label: 'Basement', value: rentalTypeEnum.BASEMENT },
  { label: 'Room', value: rentalTypeEnum.ROOM },
];

function AutoCompleteAddressFormikWrapper({ name, placeholder }) {
  const { values, setFieldValue } = useFormikContext();
  const pathToAddress = `${name}`;

  const initialAddress = deepGet(values, pathToAddress);
  const handleAddressChange = (newAddress) => {
    setFieldValue(pathToAddress, newAddress);
  };

  return (
    <AutoCompleteAddress
      initialAddress={initialAddress}
      onAddressChange={handleAddressChange}
      placeholder={placeholder}
    />
  );
}

function View() {
  const navigate = useNavigate();
  const [managerData] = useState({
    properties: [],
  });

  const [newManager, { isLoading: isNewLoading }] = useNewManagersMutation();

  console.log('Manager Data?', managerData);
  return (
    <Formik
      onSubmit={({ properties, ...managerData }) => {
        newManager({
          buildings: properties,
          manager: managerData,
        }).then(() => {
          navigate('/dashboard/managers');
        });
      }}
      initialValues={{
        ...managerData,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().email().required('Required'),
        phone: Yup.string()
          .matches(PHONE_REGEX, 'Phone number is not valid')
          .required(),
        durationOfContract: Yup.number()
          .required('This field is required')
          .integer('The value must be an integer')
          .min(1, 'The value must be at least 1')
          .max(144, 'The value cannot be greater than 144'),
        properties: Yup.array().of(buildingSchema).optional(),
      })}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        isValid,
        values,
        setFieldValue,
      }) => {
        return (
          <Form>
            <DashboardWrapperLayout title='Managers'>
              <div className='flex flex-col w-full gap-6'>
                {/* breadcrumb */}
                <div className='flex gap-3 items-center'>
                  <Link
                    to='/dashboard/home'
                    className='font-normal text-base text-[#CED0CE]'
                  >
                    Dashboard
                  </Link>
                  <span className='font-normal text-base text-[#CED0CE]'>
                    {'>'}
                  </span>
                  <Link
                    to='/dashboard/managers'
                    className='text-[#CED0CE] text-base font-normal'
                  >
                    Managers{' '}
                  </Link>
                  <span className='font-normal text-base text-[#CED0CE]'>
                    {'>'}
                  </span>
                  <Link to='' className='text-[#191923] text-base font-normal'>
                    New{' '}
                  </Link>
                </div>

                {/* Manager Fields Table */}
                <div className='w-full rounded-[10px] gap-5'>
                  {/* header */}
                  <div className='flex w-full items-center bg-[#E1E6EF] text-[#191923] text-base font-bold px-5 py-5 rounded-t-[10px]'>
                    {`Managers Information`}
                  </div>

                  {/* body content */}
                  <div className='flex w-full flex-col gap-6 px-2 py-2 border'>
                    {/* Manager Details */}
                    <div className='grid w-full grid-cols-1 gap-y-10 gap-x-6 p-5'>
                      {/* Contact Name */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Contact Name
                          </label>
                          <Input
                            placeholderText='Contact Name'
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='name'
                          />
                          <FormError name='name' />
                        </div>
                      </div>

                      {/* Email */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Email
                          </label>
                          <Input
                            placeholderText='manager@email.com'
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='email'
                          />
                          <FormError name='email' />
                        </div>
                      </div>

                      {/* Phone */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Phone
                          </label>
                          <Input
                            placeholderText='18005559393'
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='phone'
                          />
                          <FormError name='phone' />
                        </div>
                      </div>

                      {/* Address  */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Address
                          </label>
                          <AutoCompleteAddressFormikWrapper
                            name={`address`}
                            placeholder='Enter Address'
                          />
                        </div>
                      </div>

                      {/* DurationOfContract */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Duration Of Contract [1-144 months]
                          </label>
                          <Input
                            type='number'
                            placeholderText='Duration of Contract 1-144 months'
                            value={values.durationOfContract}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='durationOfContract'
                          />
                          <FormError name='durationOfContract' />
                        </div>
                      </div>
                    </div>

                    {/* Properties of the manager */}
                    <div>
                      <FieldArray
                        name='properties'
                        render={(arrayHelpers) => {
                          return (
                            <div>
                              {values.properties.map((property, index) => {
                                const propertiesPath = `properties[${index}]`;
                                return (
                                  <div
                                    className='grid w-full grid-cols-1 gap-y-10 gap-x-6 p-5 border-y'
                                    key={propertiesPath}
                                  >
                                    {/* Building name */}
                                    <div className='w-full flex items-center gap-5'>
                                      <div className='w-full flex flex-col gap-2'>
                                        <label className='text-[#191923] text-sm font-bold'>
                                          Building Name
                                        </label>
                                        <Input
                                          placeholderText='Building Name'
                                          value={
                                            values.properties[index]
                                              .buildingName
                                          }
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          name={`${propertiesPath}.buildingName`}
                                        />
                                        <FormError
                                          name={`${propertiesPath}.buildingName`}
                                        />
                                      </div>
                                    </div>

                                    {/* Minimum Credit Score */}
                                    <div className='w-full flex items-center gap-5'>
                                      <div className='w-full flex flex-col gap-2'>
                                        <label className='text-[#191923] text-sm font-bold'>
                                          Minimum Credit Score
                                        </label>
                                        <Input
                                          placeholderText='Minimum Credit Score'
                                          value={
                                            values.properties[index]
                                              .minCreditScore
                                          }
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          name={`${propertiesPath}.minCreditScore`}
                                        />
                                        <FormError
                                          name={`${propertiesPath}.minCreditScore`}
                                        />
                                      </div>
                                    </div>

                                    {/* Minimum Income */}
                                    <div className='w-full flex items-center gap-5'>
                                      <div className='w-full flex flex-col gap-2'>
                                        <label className='text-[#191923] text-sm font-bold'>
                                          Minimum Income
                                        </label>
                                        <Input
                                          placeholderText='Minimum Income'
                                          value={
                                            values.properties[index].minIncome
                                          }
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          name={`${propertiesPath}.minIncome`}
                                        />
                                        <FormError
                                          name={`${propertiesPath}.minIncome`}
                                        />
                                      </div>
                                    </div>

                                    {/* Neighborhood */}
                                    <div className='w-full flex items-center gap-5'>
                                      <div className='w-full flex flex-col gap-2'>
                                        <label className='text-[#191923] text-sm font-bold'>
                                          Neighborhood
                                        </label>
                                        <Input
                                          placeholderText='Neighborhood'
                                          value={
                                            values.properties[index]
                                              .neighborhood
                                          }
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          name={`${propertiesPath}.neighborhood`}
                                        />
                                        <FormError
                                          name={`${propertiesPath}.neighborhood`}
                                        />
                                      </div>
                                    </div>

                                    {/* Rental Type */}
                                    <div className='flex flex-col gap-5'>
                                      <label className='text-[#191923] text-sm font-bold'>
                                        Rental Type
                                      </label>

                                      <SelectInput
                                        options={rentalTypeOptions}
                                        placeholder='Select Type'
                                        name={`${propertiesPath}.rentalType`}
                                        value={rentalTypeOptions.find(
                                          (rentalType) =>
                                            rentalType.value ===
                                            property.rentalType
                                        )}
                                        onChange={(selectedOption) => {
                                          setFieldValue(
                                            `${propertiesPath}.rentalType`,
                                            selectedOption?.value || ''
                                          );
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </div>

                                    <FormError
                                      name={`${propertiesPath}.rentalType`}
                                    />

                                    {/* Monthly Rent */}
                                    <div className='w-full flex items-center gap-5'>
                                      <div className='w-full flex flex-col gap-2'>
                                        <label className='text-[#191923] text-sm font-bold'>
                                          Monthly Rent
                                        </label>
                                        <Input
                                          placeholderText='Monthly Rent'
                                          value={
                                            values.properties[index].monthlyRent
                                          }
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          name={`${propertiesPath}.monthlyRent`}
                                        />
                                        <FormError
                                          name={`${propertiesPath}.monthlyRent`}
                                        />
                                      </div>
                                    </div>

                                    {/* Termination Fee */}
                                    <div className='w-full flex items-center gap-5'>
                                      <div className='w-full flex flex-col gap-2'>
                                        <label className='text-[#191923] text-sm font-bold'>
                                          Termination Fee
                                        </label>
                                        <Input
                                          placeholderText='Termination Fee'
                                          value={
                                            values.properties[index]
                                              .terminationFee
                                          }
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          name={`${propertiesPath}.terminationFee`}
                                        />
                                        <FormError
                                          name={`${propertiesPath}.terminationFee`}
                                        />
                                      </div>
                                    </div>

                                    <div className='w-full flex items-center gap-5'>
                                      <div className='w-full flex flex-col gap-2'>
                                        <label className='text-[#191923] text-sm font-bold'>
                                          Address
                                        </label>
                                        <AutoCompleteAddressFormikWrapper
                                          name={`${propertiesPath}.address`}
                                          placeholder='Enter Address'
                                        />
                                      </div>
                                    </div>
                                    <div className='flex pt-2'>
                                      <div className='min-width-[100px] max-width-[200px]'>
                                        <Button
                                          Icon={IoMdRemove}
                                          onClick={() => {
                                            arrayHelpers.remove(index);
                                          }}
                                          title='Remove Building'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                              <div className='flex pt-5'>
                                <div className='min-width-[100px] max-width-[200px]'>
                                  <Button
                                    Icon={IoMdAdd}
                                    onClick={() =>
                                      arrayHelpers.push({
                                        buildingName: '',
                                      })
                                    }
                                    title='Add a Building'
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className='flex'>
                      <div className='min-width-[100px] max-width-[200px]'>
                        <Button
                          Icon={IoMdSave}
                          disabled={!isValid || isNewLoading}
                          onClick={handleSubmit}
                          title='Save Manager Data'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DashboardWrapperLayout>
          </Form>
        );
      }}
    </Formik>
  );
}

export default View;
