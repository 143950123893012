import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  useGetPropertyQuery,
  useDeletePropertyMutation,
} from '../../../redux/api/ciosAdminApi/ciosAdminApi';
import RentalType from '../../../components/properties/rental-type';
import { Link, useParams } from 'react-router-dom';
import { BiEdit } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import ErrorData from '../../../components/shared/error-data';
import LoadingSpinner from '../../../components/shared/loading-spinner';
import DashboardWrapperLayout from '../../../layouts/dashboard-wrapper';
import DecimalDisplay from '../../../components/shared/decimal';
import DeleteModal from '../../../components/shared/delete-modal';
import { useNavigate } from 'react-router-dom';
import Button from 'common/components/button';

function View() {
  const { propertyId, managerId } = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const navigate = useNavigate();
  const {
    data: propertyData,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
    isFetching: isGetFetching,
  } = useGetPropertyQuery(propertyId);

  const [deleteProperty, { isLoading: isDeleteLoading }] =
    useDeletePropertyMutation();

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  if (!propertyData) return <LoadingSpinner />;
  console.log(propertyData);
  const { streetAddress1, city, state, country } = propertyData?.address || {
    streetAddress1: '',
    city: '',
    state: '',
    country: '',
  };
  console.log('IsLoading', isGetLoading || isGetFetching || isDeleteLoading);
  return (
    <DashboardWrapperLayout>
      {(isGetLoading || isGetFetching || isDeleteLoading) && (
        <LoadingSpinner center={true} />
      )}
      {deleteModalOpen && (
        <DeleteModal
          handleDelete={() => {
            deleteProperty({ id: propertyId })
              .unwrap()
              .then(() => {
                navigate(`/dashboard/managers/${managerId}`);
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Delete Failed');
              });
          }}
          onCancel={() => {
            setDeleteModalOpen(false);
          }}
        />
      )}
      <div className='flex flex-col w-full gap-6'>
        {/* Breadcrumbs */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Dashboard
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link
            to='/dashboard/managers'
            className='text-[#CED0CE] text-base font-normal'
          >
            Property Managers{' '}
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link
            to={`/dashboard/managers/${managerId}`}
            className='text-[#CED0CE] text-base font-normal'
          >
            Manager{' '}
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link to='' className='text-[#191923] text-base font-normal'>
            {propertyData.buildingName}
          </Link>
        </div>

        {/* Property Details */}
        <div className='w-full border rounded-[10px] gap-5'>
          {/* header */}
          <div className='flex w-full items-center bg-[#E1E6EF] text-[#191923] text-base font-bold px-5 py-5 rounded-t-[10px]'>
            {`Property Information`}
          </div>

          {/* Body */}
          <div className='flex w-full flex-col gap-6'>
            {/* Details */}
            <div className='grid w-full grid-cols-4 gap-y-10 gap-x-6 p-5'>
              {/* name */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>Name:</p>

                <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                  {propertyData.buildingName}
                </p>
              </div>

              {/* Address */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>Address:</p>

                <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                  {streetAddress1}
                  <span className='text-[#6A6C70] font-normal text-sm'>
                    {`${city}, ${state} ${country}`}
                  </span>
                </p>
              </div>

              {/* Min Income */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>
                  Min Income:
                </p>

                <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                  <DecimalDisplay value={propertyData.minIncome} />
                </p>
              </div>

              {/* Rent */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>Rent:</p>

                <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                  <DecimalDisplay value={propertyData.monthlyRent} />
                </p>
              </div>

              {/* Termination Fee */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>
                  Termination Fee:
                </p>

                <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                  <DecimalDisplay value={propertyData.terminationFee} />
                </p>
              </div>

              {/* Min Credit Score */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>
                  Min Credit Score:
                </p>

                <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                  {propertyData.minCreditScore}
                </p>
              </div>

              {/* Neighborhood */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>
                  Neighborhood
                </p>

                <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                  {propertyData.neighborhood}
                </p>
              </div>

              {/* RentalType */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>
                  Rental Type
                </p>

                <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                  <RentalType size='sm' rentalType={propertyData.rentalType} />
                </p>
              </div>
            </div>

            {/* buttons */}
            <div className='w-full flex justify-end items-center pb-5 pr-3'>
              <div className='flex items-center gap-5'>
                <Button
                  onClick={() => {
                    navigate(
                      `/dashboard/managers/${managerId}/properties/${propertyId}/edit`
                    );
                  }}
                  Icon={BiEdit}
                  title='Edit'
                />
                <Button
                  onClick={() => {
                    setDeleteModalOpen(true);
                  }}
                  Icon={AiOutlineDelete}
                  theme='secondary'
                  title='Delete'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapperLayout>
  );
}

export default View;
