import React from 'react';
import Select from 'react-select';

const SelectInput = ({
  options,
  placeholder,
  name = '',
  onChange,
  value,
  onFocus,
  onBlur,
  isDisabled,
  defaultInputValue,
  defaultValue,
}) => {
  return (
    <Select
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      isDisabled={isDisabled}
      onFocus={onFocus}
      value={value}
      defaultInputValue={defaultInputValue}
      defaultValue={defaultValue}
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#E1E6EF',
          primary: 'black',
        },
      })}
      classNames={{
        control: (state) =>
          state.isFocused
            ? '!border !border-[#191923] h-10 w-full !rounded-md'
            : '!border !border-[#CED0CE] h-10 w-full !rounded-md',
        indicatorSeparator: () => '!bg-transparent',
        valueContainer: () => '!px-3',
        option: () => 'cursor-pointer',
      }}
      placeholder={placeholder}
      options={options}
    />
  );
};

export default SelectInput;
