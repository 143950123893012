import React from 'react';
import { BiEdit } from 'react-icons/bi';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import StatusLabel from 'components/plans/status-label';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';
import SortButton from 'common/components/sort-button';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';
import {
  ViewIcon,
  AddIcon,
  RepeatIcon,
  LockIcon,
  CloseIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from '@chakra-ui/icons';
import {Table, HeaderRow, HeaderCell, TableBody, BodyRow, TableCell} from 'common/components/data-table'


const PlansTable = ({
  plans,
  isRounded=true,
  tenantName,
  tenantEmail,
  sortVal,
  setSort,
}) => {
  // DATA INITIALIZATION
  const navigate = useNavigateSearch();

  console.log(plans)

  return (
    <Table>
      {/* head */}
      <HeaderRow isRounded={isRounded} gridLayout='grid-cols-[220px_1fr_1fr_120px_180px]'>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='createdAt'
            label='Plan ID'
          />
        </HeaderCell>
        <HeaderCell>
          Member
        </HeaderCell>
        <HeaderCell>
          Address
        </HeaderCell>
        <HeaderCell sortColumn>
          <SortButton
            setSort={setSort}
            sortVal={sortVal}
            field='lease.startDate'
            label='Term'
          />
        </HeaderCell>
        <HeaderCell>
          Status
        </HeaderCell>
      </HeaderRow>

      {/* body */}
      <TableBody>
        {plans.map((plan, index) => (
          <BodyRow
            gridLayout='grid-cols-[220px_1fr_1fr_120px_180px]'
            key={index}
            onClick={() => {
              navigate(`/dashboard/plans/${plan.id}`);
            }}
          >
            {/* Plan ID and Create Date */}
            <TableCell
              top={plan?.id}
              bottom={`Created ${formatDateToHumanDate(plan?.createdAt)}`}
            />

            {/* profile */}
            <TableCell
              top={tenantName || plan?.user?.name ? plan?.user?.name : `${plan?.user?.firstName} ${plan?.user?.lastName}`}
              bottom={tenantEmail || plan?.user?.email}
            />

            {/* Address */}
            <TableCell
              top={`${plan?.lease?.address.streetAddress1}${plan?.lease?.address.streetAddress2 && ', '}${plan?.lease?.address.streetAddress2}`}
              bottom={`${plan?.lease?.address.city}, ${plan?.lease?.address.state} ${plan?.lease?.address.postalCode} (${plan?.lease?.landlordName})`}
            />

            {/* Start and End Date */}
            <TableCell>
              <div className='flex flex-col'>
                <span>
                  {formatDateToHumanDate(plan?.lease?.startDate)}
                </span>
                <span>
                  {formatDateToHumanDate(plan?.lease?.endDate)}
                </span>
              </div>
            </TableCell>

            {/* Status and Edit button */}
            <TableCell 
              className='justify-between'
              onClick={(e) => {
                e.stopPropagation();
                return false;
              }}
            >
              <StatusLabel size='sm' status={plan?.status} />
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label='Options'
                  icon={<HamburgerIcon />}
                  variant='outline'
                />
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/plans/${plan?.id}`);
                    }}
                    icon={<ViewIcon />}
                  >
                    View Plan
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/plans/${plan?.id}/edit`);
                    }}
                    icon={<AddIcon />}
                  >
                    Edit Plan
                  </MenuItem>
                </MenuList>
              </Menu>
            </TableCell>

          </BodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PlansTable;
