export const DOTS = '...';
export const REQUESTED_PLAN = 1;
export const ACTIVE_PLAN = 2;

// Payment plans
export const PREMIUM_PAYMENT = 1;
export const MONTHLY_PAYMENT = 2;

export const planStatusEnum = {
  created: 'created',
  pending: 'pending',
  review: 'review',
  active: 'active',
  complete: 'complete',
  rejected: 'rejected',
  cancelled: 'cancelled',
};

export const claimStatusEnum = {
  // CLAIMS
  Draft: 'Draft',
  Submitted: 'submitted',
  Completed: 'Completed',
  Review: 'in Review',
  Expired: 'Expired',
  Denied: 'Denied',
  Approved: 'Approved',
  Confirmed: 'Confirmed',
};
export const claimTypeEnum = {
  NEW_JOB: 'newJob',
  PURCHASED_HOME: 'homePurchase',
  OTHER_CLAIM: 'other',
};
export const stripeKYCEnum = {
  pending: 'pending',
  failed: 'error',
  verified: 'verified',
};

export const rentalTypeEnum = {
  APARTMENT: 'apartment',
  CONDO: 'condo',
  HOUSE: 'house',
  TOWNHOUSE: 'townhouse',
  BASEMENT: 'basement',
  ROOM: 'room',
};

export const tenantStatusEnum = {
  NEW: 'New',
  INVITED: 'Invited',
  ACCEPTED: 'Accepted',
  DELETED: 'Deleted',
  VERIFIED: 'Verified',
  NOT_VERIFIED: 'Not Verified'
};

export const managerContractStatusEnum = {
  ACTIVE: 'active',
  DELETED: 'deleted',
  TERMINATED: 'terminated',
  DID_NOT_RENEW: 'did-not-renew',
  ERROR: 'error',
};

export const documentStatusEnum = {
  NONE: 'none',
  UPLOADED: 'uploaded',
  REJECTED: 'rejected',
  VERIFIED: 'verified',
};

export const ownerStatusEnum = {
  ACTIVE: 'Active',
  DELETED: 'Deleted',
};

export const MAX_DOCUMENT_SIZE = 20971520; // 20MB
