import Route from './route';
import defaultFetchFn from '../fetch';

function delayFn(delay) {
  console.log('Delay created?', delay);
  return async function (req) {
    console.log('The delay function?', delay);
    await new Promise((resolve) => setTimeout(resolve, delay));
    console.log('Out of delay?');
    return defaultFetchFn(req);
  };
}

function dataHandler(args) {
  const { delay, status, ok, json } = args || {};

  return async function (req) {
    console.log('The request coming in?', req, 'the delay?', delay);
    await new Promise((resolve) => setTimeout(resolve, delay || 2));
    const responseBody = JSON.stringify(json);
    return new window.Response(responseBody, {
      status: status || 200,
      statusText: ok ? 'OK' : 'Error',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };
}

const response = {
  data: [
    {
      address: {
        streetAddress1: '19 Marymount Place',
        city: 'Winnipeg',
        state: 'Manitoba',
        zipCode: 'R3T 4E6',
        country: 'Canada',
      },
      phoneConfirmed: false,
      emailConfirmed: false,
      connectedAccountStatus: 'Not Created',
      image: 'default.jpg',
      contractStatus: 'did-not-renew',
      durationOfContract: 1,
      name: 'Manger Dudeasdfasdf',
      phone: '+120455d58888',
      email: 'dudasdfasdfe@manager.com',
      _kind: 'Manager',
      createdAt: '2023-08-30T22:57:07.627Z',
      updatedAt: '2023-11-29T19:50:18.803Z',
      id: '64efc943deb19a3467faae98',
    },
  ],
  status: 'success',
  meta: { count: 1 },
};

const routes = [
  // Route({
  //   slug: "/managers/64efc943deb19a3467faae98",
  //   method: "PATCH",
  //   match() {
  //     console.log("The match?", this);
  //     return false;
  //   },
  //   handler: dataHandler({
  //     status: 400,
  //     delay: 3000,
  //     ok: false,
  //     json: {
  //       status: "error",
  //       err: {},
  //     },
  //   }),
  // }),
  // Route({
  //   slug: "/managers",
  //   method: "GET",
  //   handler: dataHandler({ json: response }),
  // }),
  // Route({
  //   slug: "/managers/64efc943deb19a3467faae98",
  //   method: "GET",
  //   handler: delayFn(1250),
  // }),
  // Route({ slug: "/managers", method: "GET", handler: delayFn(2000) }),
];

export default routes;
