import { useState } from 'react';
import ErrorData from '../../../components/shared/error-data';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../../components/shared/loading-spinner';
import DeleteModal from '../../../components/shared/delete-modal';
import { AiOutlineDelete } from 'react-icons/ai';
import { BiEdit } from 'react-icons/bi';
import { Link, useParams } from 'react-router-dom';
import PlansTable from '../../../components/plans/plans-table';
import Button from 'common/components/button';
import EmptyData from '../../../components/shared/empty-data';
import StatusLabel from '../../../components/owners/status-label';
import DashboardWrapperLayout from '../../../layouts/dashboard-wrapper';
import { isEmpty } from '../../../utils/isEmpty';
import {
  useGetOwnerQuery,
  useDeleteOwnerMutation,
} from '../../../redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';

const View = () => {
  const navigate = useNavigate();
  const { ownerId } = useParams();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const {
    data: ownerData,
    isError: isGetError,
    error: getError,
    isLoading: isGetLoading,
    isFetching: isGetFetching,
  } = useGetOwnerQuery(ownerId);
  const [
    deleteOwner,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
    // { isLoading: updatePlanLoading },
  ] = useDeleteOwnerMutation();

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  if (!ownerData) return <LoadingSpinner />;
  return (
    <DashboardWrapperLayout title='Owners'>
      {(isUpdateLoading ||
        isUpdateFetching ||
        isGetFetching ||
        isGetLoading) && <LoadingSpinner />}
      {deleteModalOpen && (
        <DeleteModal
          handleDelete={() => {
            deleteOwner({ id: ownerId })
              .unwrap()
              .then(() => {
                navigate('/dashboard/owners');
              })
              .catch((e) => {
                toast.error(e?.data?.msg || 'Delete Failed');
              });
          }}
          onCancel={() => {
            setDeleteModalOpen(false);
          }}
        />
      )}
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Dashboard
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link
            to='/dashboard/owners'
            className='text-[#CED0CE] text-base font-normal'
          >
            Owners{' '}
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link to='' className='text-[#191923] text-base font-normal'>
            Details{' '}
          </Link>
        </div>

        {/* Owner Details Table */}
        <div className='w-full rounded-[10px] border gap-5'>
          {/* header */}
          <div className='flex w-full items-center bg-[#E1E6EF] text-[#191923] text-base font-bold px-5 py-5 rounded-t-[10px]'>
            {`Owner's Information`}
          </div>

          {/* body content */}
          <div className='flex w-full flex-col gap-6'>
            {/* details */}
            <div className='grid w-full grid-cols-4 gap-y-10 gap-x-6 p-5'>
              {/* name */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>Name:</p>

                <p className='inline-flex flex-col gap-1 text-sm text-[#191923] font-bold'>
                  {ownerData?.name}
                </p>
              </div>

              {/* status */}
              <div className='flex flex-col gap-5'>
                <p className='text-xs text-[#191923] font-medium'>Status:</p>

                <StatusLabel status={ownerData.status} size='base' />
              </div>
            </div>

            {/* buttons */}
            <div className='w-full flex justify-end items-center pb-5 pr-3'>
              <div className='flex items-center gap-5'>
                <Button
                  onClick={() => {
                    navigate(`/dashboard/owners/${ownerId}/edit`);
                  }}
                  Icon={BiEdit}
                  title='Edit'
                />
                <Button
                  onClick={() => {
                    setDeleteModalOpen(true);
                  }}
                  Icon={AiOutlineDelete}
                  theme='secondary'
                  title='Delete'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardWrapperLayout>
  );
};

export default View;
