import { useSelector, useDispatch } from 'react-redux';
import {
  selectRequestManagerForPlan,
  resetManagerForPlan,
} from '../../../redux/features/misc/miscSlice';

function View() {
  const dispatch = useDispatch();
  const requestManagerForPlan = useSelector(selectRequestManagerForPlan);
  const hasPlan = requestManagerForPlan;

  return (
    <div
      className='fixed bottom-10 right-10 p-4 z-50 cursor-pointer border rounded-xl shadow-xl'
      onClick={() => {
        dispatch(resetManagerForPlan());
      }}
    >
      {hasPlan ? 'Cancel Select Manager' : ''}
      {hasPlan && (
        <div
          className={`animate-ping inline-block relative left-2 h-[12px] w-[12px] bg-red-400 rounded-[50%]`}
        />
      )}
    </div>
  );
}

export default View;
