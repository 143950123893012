import React, { useState } from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function View({selected, editPath, viewPath}) {
  const navigate = useNavigate();
  const [_selected, setSelected] = useState(selected);
  return (
    <div className='min-width-xl'>
      <ButtonGroup>
        <Button
          colorScheme="gray"
          variant={_selected === 'edit' ? 'solid' : 'outline'}
          onClick={() => {
            setSelected('edit')
            navigate(editPath)
          }}
        >
          Edit Details
        </Button>
        <Button
          colorScheme="gray"
          variant={_selected === 'view' ? 'solid' : 'outline'}
          onClick={() => {
            setSelected('view')
            navigate(viewPath)
          }}
        >
          View Details
        </Button>
      </ButtonGroup>
    </div>
  )
}

export default View
