import {
  Button,
  Icon,
} from '@chakra-ui/react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6';

function View({setSort, sortVal, field, label}) {
  let rightIcon = <span/>;
  const sortAsc = field;
  const sortDesc = `-${field}`;

  console.log(sortVal, field, label)
  if (sortVal === sortAsc) {
    rightIcon = <Icon as={FaChevronUp} boxSize={3} />
  } else if (sortVal === sortDesc) {
    rightIcon = <Icon as={FaChevronDown} boxSize={3} />
  }
  return (
    <Button
      onClick={() => {
        if (sortVal === sortDesc) {
          setSort(sortAsc)
        } else if (sortVal === sortAsc) {
          setSort(sortDesc)
        } else {
          setSort(sortAsc)
        }
      }}
      rightIcon={rightIcon}
      variant='outline'
      className='w-full flex justify-start text-[#191923] text-sm font-sm px-2 py-2 h-full tracking-wide uppercase'
    >
      {label}
    </Button>
  )
}

export default View
