import React from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaRegCircleUser } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import StatusLabel from '../status-label';
import ClaimType from '../claim-type';

const ClaimsTable = ({ claims }) => {
  // DATA INITIALIZATION
  const navigate = useNavigate();

  return (
    <table className='flex flex-col w-full'>
      {/* head */}
      <thead className='w-full'>
        <tr className='grid grid-cols-5 text-left first:rounded-t-[10px] bg-[#E1E6EF]'>
          <th className='w-full py-3 px-6 text-[#6A6C70] text-xs font-medium'>
            NAME
          </th>
          <th className='w-full py-3 px-6 text-[#6A6C70] text-xs font-medium'>
            ADDRESS
          </th>
          <th className='w-full py-3 px-6 text-[#6A6C70] text-xs font-medium'>
            CLAIM ID
          </th>
          <th className='w-full py-3 px-6 text-[#6A6C70] text-xs font-medium'>
            TYPE
          </th>
          <th className='w-full py-3 px-6 text-[#6A6C70] text-xs font-medium'>
            STATUS
          </th>
        </tr>
      </thead>

      {/* body */}
      <tbody className='w-full'>
        {claims.map((claim, index) => (
          <tr
            key={index}
            onClick={() => {
              navigate(`/dashboard/claims/${claim.id}`);
            }}
            className='grid grid-cols-5 w-full border-b border-x border-[#E1E6EF] last:rounded-b-[10px] cursor-pointer'
          >
            {/* profile */}
            <td className='w-full py-3 px-6 flex items-center text-[#191923]'>
              <div className='flex items-center gap-4'>
                <div className='inline-flex flex-col'>
                  <p className='text-sm font-medium m-0'>
                    {claim?.userId.name}
                  </p>
                  <p className='text-[#6A6C70] text-sm m-0'>
                    {claim?.userId?.email}
                  </p>
                </div>
              </div>
            </td>

            {/* Address */}
            <td className='w-full py-3 px-6 flex items-center text-[#191923]'>
              <div className='inline-flex flex-col'>
                <p className='text-sm m-0'>
                  {claim?.planId?.lease?.address?.street}
                </p>
                <p className='text-[#6A6C70] text-sm m-0'>
                  {`${claim?.planId?.lease?.address?.city}, ${claim?.planId?.lease?.address?.state}. ${claim?.planId?.lease?.address?.country}`}
                </p>
              </div>
            </td>

            {/* Claim ID */}
            <td className='w-full py-3 text-sm px-6 flex flex-col items-left text-[#191923] break-words'>
              <div>{claim?.name}</div>
              <div>{claim?.id}</div>
            </td>

            {/* Type */}
            <td className='w-full py-3 text-sm px-6 flex items-center text-[#191923]'>
              <ClaimType status={claim.claimType} size='sm' />
            </td>

            {/* Status and Edit button */}
            <td className='w-full py-3 px-6 flex items-center'>
              <div className=' inline-flex justify-between w-full items-center'>
                {/* Status */}
                <StatusLabel size='sm' status={claim?.status} />

                {/* Edit button */}
                <button
                  onClick={() => {
                    navigate(`/dashboard/claims/${claim?.id}`);
                  }}
                  className='inline-flex gap-2 items-center rounded-3xl transition-all px-3 py-2 hover:bg-gray-100'
                >
                  <BiEdit className='text-[#24242E] text-xl' />
                  <span className='text-base text-[#24242E]'>Edit</span>
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ClaimsTable;
