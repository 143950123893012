import { Link, useParams } from 'react-router-dom';
import { IoMdSave } from 'react-icons/io';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import ErrorData from '../../../components/shared/error-data';
import Input from '../../../components/shared/input';
import FormError from '../../../components/shared/form-error';
import AutoCompleteAddress from '../../../components/shared/autoaddress';
import SelectInput from '../../../components/shared/select-input';
import DashboardWrapperLayout from '../../../layouts/dashboard-wrapper';
import LoadingSpinner from '../../../components/shared/loading-spinner';
import { Form, Formik, useFormikContext } from 'formik';
import deepGet from '../../../utils/deepGet';
import {
  useGetManagerQuery,
  useUpdateManagerMutation,
} from '../../../redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import { managerContractStatusEnum } from '../../../utils/enums';
import { PHONE_REGEX } from '../../../utils/regex';
import Button from 'common/components/button';

const managerContractStatusOptions = [
  {
    label: 'Active',
    value: managerContractStatusEnum.ACTIVE,
  },
  {
    label: 'Deleted',
    value: managerContractStatusEnum.DELETED,
  },
  {
    label: 'Terminated',
    value: managerContractStatusEnum.TERMINATED,
  },
  {
    label: 'Did Not Renew',
    value: managerContractStatusEnum.DID_NOT_RENEW,
  },
  {
    label: 'Error',
    value: managerContractStatusEnum.ERROR,
  },
];

function AutoCompleteAddressFormikWrapper({ name, placeholder }) {
  const { values, setFieldValue } = useFormikContext();
  const pathToAddress = `${name}`;

  const initialAddress = deepGet(values, pathToAddress);
  const handleAddressChange = (newAddress) => {
    setFieldValue(pathToAddress, newAddress);
  };

  return (
    <AutoCompleteAddress
      initialAddress={initialAddress}
      onAddressChange={handleAddressChange}
      placeholder={placeholder}
    />
  );
}

function View() {
  const { managerId } = useParams();
  const navigate = useNavigate();
  const {
    data: managerData,
    isError: isGetError,
    error: getError,
    isLoading: isLoadingManager,
    isFetching: isFetchingManager,
  } = useGetManagerQuery(managerId);
  const [updateManager, { isLoading: isUpdatingManager }] =
    useUpdateManagerMutation();

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  // TODO handle no data condition better
  if (!managerData)
    return (
      <div>
        <LoadingSpinner center='true' />
      </div>
    );

  console.log('Manager Data?', managerData);
  return (
    <Formik
      onSubmit={(value) => {
        updateManager({ id: managerId, ...value })
          .unwrap()
          .then((x) => {
            navigate('/dashboard/managers');
          })
          .catch((e) => {
            toast.error(e?.data?.msg || 'Update Failed');
          });
      }}
      initialValues={{
        name: managerData.name,
        email: managerData.email,
        contractStatus: managerData.contractStatus,
        phone: managerData.phone,
        address: managerData.address,
        durationOfContract: managerData.durationOfContract,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        email: Yup.string().email().required('Required'),
        phone: Yup.string()
          .matches(PHONE_REGEX, 'Phone number is not valid')
          .required(),
        durationOfContract: Yup.number()
          .required('This field is required')
          .integer('The value must be an integer')
          .min(1, 'The value must be at least 1')
          .max(144, 'The value cannot be greater than 144'),
      })}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        isValid,
        values,
        setFieldValue,
      }) => {
        return (
          <Form>
            <DashboardWrapperLayout title='Managers'>
              {(isLoadingManager || isUpdatingManager || isFetchingManager) && (
                <LoadingSpinner center='true' />
              )}
              <div className='flex flex-col w-full gap-6'>
                {/* breadcrumb */}
                <div className='flex gap-3 items-center'>
                  <Link
                    to='/dashboard/home'
                    className='font-normal text-base text-[#CED0CE]'
                  >
                    Dashboard
                  </Link>
                  <span className='font-normal text-base text-[#CED0CE]'>
                    {'>'}
                  </span>
                  <Link
                    to='/dashboard/managers'
                    className='text-[#CED0CE] text-base font-normal'
                  >
                    Managers{' '}
                  </Link>
                  <span className='font-normal text-base text-[#CED0CE]'>
                    {'>'}
                  </span>
                  <Link
                    to={`/dashboard/managers/${managerId}`}
                    className='text-[#CED0CE] text-base font-normal'
                  >
                    Details{' '}
                  </Link>
                  <span className='font-normal text-base text-[#CED0CE]'>
                    {'>'}
                  </span>
                  <Link
                    to={`/dashboard/managers/${managerId}`}
                    className='text-[#191923] text-base font-normal'
                  >
                    Edit{' '}
                  </Link>
                </div>

                {/* Manager Fields Table */}
                <div className='w-full rounded-[10px] gap-5 border border-gray'>
                  {/* header */}
                  <div className='flex w-full items-center bg-[#E1E6EF] text-[#191923] text-base font-bold px-5 py-5 rounded-t-[10px]'>
                    {`Managers Information`}
                  </div>

                  {/* body content */}
                  <div className='flex w-full flex-col gap-6 px-2 py-2 border'>
                    {/* Details */}
                    <div className='grid w-full grid-cols-1 gap-y-10 gap-x-6 p-5'>
                      {/* Contact Name */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Contact Name
                          </label>
                          <Input
                            placeholderText='Contact Name'
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='name'
                          />
                          <FormError name='name' />
                        </div>
                      </div>

                      {/* Email */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Email
                          </label>
                          <Input
                            placeholderText='manager@email.com'
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='email'
                          />
                          <FormError name='email' />
                        </div>
                      </div>

                      {/* Phone */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Phone
                          </label>
                          <Input
                            placeholderText='manager@phone.com'
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='phone'
                          />
                          <FormError name='phone' />
                        </div>
                      </div>

                      {/* Address  */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Address
                          </label>
                          <AutoCompleteAddressFormikWrapper
                            name={`address`}
                            placeholder='Enter Address'
                          />
                        </div>
                      </div>

                      {/* DurationOfContract */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Duration Of Contract [1-144 months]
                          </label>
                          <Input
                            type='number'
                            placeholderText='Duration of Contract 1-144 months'
                            value={values.durationOfContract}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='durationOfContract'
                          />
                          <FormError name='durationOfContract' />
                        </div>
                      </div>

                      {/* Status Select */}
                      <div className='flex flex-col gap-5'>
                        <label className='text-[#191923] text-sm font-bold'>
                          Change status
                        </label>

                        <SelectInput
                          options={managerContractStatusOptions}
                          placeholder='Select Status'
                          name='values.contractStatus'
                          value={managerContractStatusOptions.find(
                            (status) => status.value === values.contractStatus
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              'contractStatus',
                              selectedOption?.value || ''
                            );
                          }}
                          onBlur={handleBlur}
                        />
                      </div>
                      {/* Save Button */}
                      <div className='flex'>
                        <div className='min-width-[100px] max-width-[200px]'>
                          <Button
                            disabled={!isValid}
                            Icon={IoMdSave}
                            onClick={handleSubmit}
                            title='Save Manager Data'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DashboardWrapperLayout>
          </Form>
        );
      }}
    </Formik>
  );
}

export default View;
