import { useState } from 'react';
import ErrorData from '../../../components/shared/error-data';
import Button from 'common/components/button';
import Input from 'components/shared/input';
import { Link } from 'react-router-dom';
import MembersTable from '../../../components/members/members-table';
import EmptyData from '../../../components/shared/empty-data';
import DashboardWrapperLayout from '../../../layouts/dashboard-wrapper';
import TablePaginateWrapper from '../../../layouts/table-paginate-wrapper';
import LoadingSpinner from '../../../components/shared/loading-spinner';
import { isEmpty } from '../../../utils/isEmpty';
import { useFindTenantsQuery } from '../../../redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';

const Tenants = ({ statuses }) => {
  const navigate = useNavigate();
  // DATA INITIALIZATION
  const pageSize = 25;

  // STATES
  const [currentPage, setCurrentPage] = useState(1);
  const [sortVal, setSort] = useState('-createdAt');
  const [textFilter, setTextFilter] = useState('');

  const {
    data: tenantsQuery,
    isError: isGetError,
    error: getError,
    isLoading: findTenantsLoading,
  } = useFindTenantsQuery({
    limit: pageSize,
    page: currentPage,
    sort: sortVal,
    textFilter,
    status: statuses,
  });

  const { data: tenantsData, meta: tenantsMeta } = tenantsQuery || {
    data: [],
    meta: { count: 0 },
  };

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }
  console.log('All tenants?', tenantsData);

  // HOOKS

  return (
    <DashboardWrapperLayout title='Members'>
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Dashboard
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link to='/dashboard/plans' className='text-[#191923] text-base font-normal'>
            Members
          </Link>
        </div>

        <div className='flex gap-3 justify-between'>
          <Input
            placeholderText='Search'
            value={textFilter}
            onChange={(e) => {
              setTextFilter(e.target.value);
            }}
            name='textFilter'
          />
          <Button
            title='Add Member'
            onClick={() => navigate(`/dashboard/members/new`)}
            wFull={false}
            className='px-8'
          />
        </div>

        {/* table */}
        {findTenantsLoading && <LoadingSpinner center={true} />}
        {isEmpty(tenantsData) ? (
          <EmptyData />
        ) : (
          <TablePaginateWrapper
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            currentPage={currentPage}
            totalCount={tenantsMeta.count}
          >
            <MembersTable
              sortVal={sortVal}
              setSort={setSort}
              members={tenantsData}
            />
          </TablePaginateWrapper>
        )}
      </div>
    </DashboardWrapperLayout>
  );
};

export default Tenants;
