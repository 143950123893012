import React from 'react';
import TableDataRow from './table-data-row';

const ManagersTable = ({ managers }) => {
  return (
    <table className='flex flex-col w-full'>
      {/* head */}
      <thead className='w-full'>
        <tr className='grid grid-cols-5 text-left bg-[#E1E6EF]'>
          <th className='w-full py-3 px-6 text-[#6A6C70] text-xs font-medium'>
            NAME
          </th>
          <th className='w-full py-3 px-6 text-[#6A6C70] text-xs font-medium'>
            PHONE
          </th>
          <th className='w-full py-3 px-6 text-[#6A6C70] text-xs font-medium'>
            DURATION
          </th>
          <th className='w-full py-3 px-6 text-[#6A6C70] text-xs font-medium'>
            STATUS
          </th>
          <th />
        </tr>
      </thead>

      {/* body */}
      <tbody className='w-full'>
        {managers.map((manager, index) => (
          <TableDataRow
            key={index}
            id={manager?.id}
            name={manager?.name}
            phone={manager?.phone}
            duration={manager?.durationOfContract}
            status={manager?.contractStatus}
          />
        ))}
      </tbody>
    </table>
  );
};

export default ManagersTable;
