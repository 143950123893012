import React, { useState, useRef } from 'react';
import Toggle from 'components/shared/toggle';
import { useSearchParams } from 'react-router-dom';
import CopyTextComponent from 'components/shared/copy-text';
import StatusLabel from 'components/plans/status-label';
import { StatusLabel as MemberStatusLabel } from 'components/members/status-label';
import AutoCompleteAddress from 'components/shared/autoaddress';
import * as Yup from 'yup';
import moment from 'moment';
import { isEmpty } from 'utils/isEmpty';
import { useDispatch } from 'react-redux';
// import FormError from "@cios/common/dist/components/shared/form-error";
// import { FormError } from "@cios/common";
import FormError from 'components/shared/form-error';
import DocumentPreview from 'components/shared/document-preview';
import ErrorData from 'components/shared/error-data';
import { toast } from 'react-toastify';
import { Form, Formik, useFormikContext } from 'formik';
import { RiEdit2Fill } from 'react-icons/ri';
import { Link, useParams, useNavigate } from 'react-router-dom';
import PaymentPlanCard from 'components/plans/payment-plan-card';
import CheckBox from 'components/shared/checkbox';
import Input from 'components/shared/input';
import SelectInput from 'components/shared/select-input';
import AccordionWrapperLayout from 'layouts/accordion-wrapper';
import DashboardWrapperLayout from 'layouts/dashboard-wrapper';
import { ACTIVE_PLAN, REQUESTED_PLAN, documentStatusEnum } from 'utils/enums';
import { toDDMMYYYY, toDatePickerFormat } from 'utils/date';
import { uploadFileToS3 } from 'utils/uploadS3';
import {
  useFindOwnersQuery,
  useClaimAccountTenantReqMutation,
  useGetPlanQuery,
  useUploadRequestUrlMutation,
  useImpersonateTenantMutation,
} from 'redux/api/ciosAdminApi/ciosAdminApi';
import { planStatusEnum } from 'utils/enums';
import LoadingSpinner from 'components/shared/loading-spinner';
import filterLabelValues from 'utils/filterLabelValues';
import FileUploadInput from 'components/shared/file-upload-input';
import {
  documentStatusOptions,
  planCurrencyOptions,
} from 'utils/selectOptions';
import Button from 'common/components/button';
import { InfoCard, InfoGrid, InfoField } from 'common/components/info-card';
import { formatDateToHumanDate } from 'common/utils/formDateToHumanTime';

function ClaimAccountArea({ userId, planId }) {
  const [claimAccount, { isLoading }] = useClaimAccountTenantReqMutation();
  const [claimUrl, setClaimUrl] = useState('');
  return (
    <>
      <Button
        title='Send Activation Email'
        disabled={isLoading}
        onClick={() => {
          claimAccount({ id: userId, planID: planId })
            .unwrap()
            .then(({ url }) => {
              setClaimUrl(url);
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Send failed');
            });
        }}
        wFull={false}
        className='px-6'
      />
      {claimUrl && (
        <CopyTextComponent text={'Copy Activation Link'} copyText={claimUrl} />
      )}
    </>
  );
}

const PlanDetails = () => {
  const navigate = useNavigate();
  const { planId } = useParams();
  const [uploadRequestUrl] = useUploadRequestUrlMutation();
  const [impersonateTenant, { isLoading: isImpersonateLoading }] =
    useImpersonateTenantMutation();

  const {
    data: planQuery,
    isError: isGetError,
    error: getError,
  } = useGetPlanQuery(planId);

  const {
    data: ownersQuery,
    isError: isGetOwnerError,
    error: getOwnerError,
    isLoading: findOwnersLoading,
  } = useFindOwnersQuery({
    limit: 100,
    page: 1,
    status: 'Active',
    sort: '-name',
  });

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  // TODO Have loading screen, or something better if no data available
  if (!planQuery) {
    return <LoadingSpinner />;
  }

  const { data: planData } = planQuery || {
    data: {},
  };

  // Query for owners by ID
  const ownerOptions = (ownersQuery?.data || []).map((o) => {
    return { value: o.id, label: o.name };
  });

  const owner =
    ownerOptions.find((status) => status.value === planData?.owner) ||
    'No Building Owner';

  return (
    <DashboardWrapperLayout title={`Plan ${planData.id.toUpperCase()}`}>
      {(false || false) && <LoadingSpinner center={true} />}
      <div className='flex flex-col w-full gap-6'>
        {/* Combined breadcrumb + edit toggle header */}
        <div className='w-full flex gap-3 justify-between'>
          {/* breadcrumb */}
          <div className='flex gap-3 items-center'>
            <Link
              to='/dashboard/home'
              className='font-normal text-base text-[#CED0CE]'
            >
              Dashboard
            </Link>
            <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
            <Link
              to='/dashboard/plans'
              className='text-[#CED0CE] text-base font-normal'
            >
              Plans
            </Link>
            <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
            <Link
              to={`/dashboard/plans/${planData?.id}`}
              className='text-[#CED0CE] text-base font-normal'
            >
              {planData?.id.toUpperCase()}
            </Link>
            <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
            <Link
              to={`/dashboard/plans/${planData?.id}`}
              className='text-[#191923] text-base font-normal'
            >
              View
            </Link>
          </div>

          <Toggle
            selected={'view'}
            editPath={`/dashboard/plans/${planData?.id}/edit`}
            viewPath={`/dashboard/plans/${planData?.id}`}
          />
        </div>

        {/* Action buttons */}
        <div className='w-full flex gap-2 justify-end items-center'>
          {/* Show account activation conditionally based on the email confirmation status */}
          {!planData.user.emailConfirmed && (
            <ClaimAccountArea userId={planData.user.id} />
          )}
          <Button
            onClick={() => {
              navigate(`/dashboard/members/${planData.user.id}`);
            }}
            title='Member Details'
            wFull={false}
            className='px-6'
          />
          <Button
            disabled={isImpersonateLoading}
            onClick={() => {
              impersonateTenant({ id: planData.user.id })
                .unwrap()
                .then(({ token }) => {
                  let url = `${process.env.REACT_APP_IMPERSONATION_URL}?token=${token}&planId=${planData.id}`;
                  window.open(url, '_blank');
                });
              /* location.href = */
              /* navigate(`/dashboard/plans/new?userID=${tenantData.id}`); */
            }}
            theme='secondary'
            title='Log In As User'
            wFull={false}
            className='px-6'
          />
        </div>

        {/* Member Details Table */}
        <InfoCard title='Member Information'>
          <InfoGrid>
            <InfoField title='Name'>
              {planData?.user?.name ? planData?.user?.name : `${planData?.user?.firstName} ${planData?.user?.lastName}`}
            </InfoField>
            <InfoField title='Email'>{planData?.user?.email}</InfoField>
            <InfoField title='Phone'>{planData?.user?.phone}</InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Member Status'>
              <MemberStatusLabel status={planData?.user.status} size='base' />
            </InfoField>
            <InfoField title='KYC Verification'>
              {planData?.user?.stripeKyc?.status === 'verified' ? (
                <MemberStatusLabel status={'Verified'} size='base' />
              ) : (
                <MemberStatusLabel status={'Not Verified'} size='base' />
              )}
            </InfoField>
            <InfoField title='Email Verification'>
              {planData?.user?.emailConfirmed ? (
                <MemberStatusLabel status={'Verified'} size='base' />
              ) : (
                <MemberStatusLabel status={'Not Verified'} size='base' />
              )}
            </InfoField>
            <InfoField title='Phone Verification'>
              {planData?.user?.phoneConfirmed ? (
                <MemberStatusLabel status={'Verified'} size='base' />
              ) : (
                <MemberStatusLabel status={'Not Verified'} size='base' />
              )}
            </InfoField>
          </InfoGrid>
        </InfoCard>

        <InfoCard title='Plan Information'>
          <InfoGrid>
            <InfoField title='Plan Status'>
              <StatusLabel status={planData?.status} />
            </InfoField>
            <InfoField title='Application Progress'>
              Step {planData?.step} / 5
            </InfoField>
            <InfoField title='Created On'>
              {formatDateToHumanDate(planData?.createdAt)}
            </InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Payment Plan'>
              {planData?.payment.choice == 'monthly'
                ? 'Monthly'
                : planData?.payment.choice == 'onetime'
                  ? 'One-Time'
                  : 'No Payment Plan Selected'}
            </InfoField>
            <InfoField title='Plan Term'>
              {planData?.payment.term} months
            </InfoField>
            {/* Only show payment information if there is */}
            {planData?.payment.choice != 'none' && (
              <>
                <InfoField title='Base Rate'>
                  {planData?.payment.monthlyRentPercentageRate * 100}%
                </InfoField>
                <InfoField title='One-Time Rate'>
                  {(1 - planData?.payment.oneTimeDiscountedRate) *
                    planData?.payment.monthlyRentPercentageRate *
                    100}
                  %
                </InfoField>
                <InfoField title='Discount Rate'>
                  {planData?.payment.oneTimeDiscountedRate * 100}%
                </InfoField>
                <InfoField title='One-Time Total Amount'>
                  ${planData?.payment.oneTimePayment} {planData?.lease.currency}
                </InfoField>
                <InfoField title='One-Time Monthly Amount'>
                  ${planData?.payment.oneTimeMonthlyAmt}{' '}
                  {planData?.lease.currency}
                </InfoField>
                <InfoField title='Monthly Recurring Amount'>
                  ${planData?.payment.monthlyPayment} {planData?.lease.currency}
                </InfoField>
              </>
            )}
          </InfoGrid>
        </InfoCard>
        <InfoCard title='Lease Information'>
          <InfoGrid>
            <InfoField title='Landlord Name'>
              {planData?.lease?.landlordName}
            </InfoField>
            <InfoField title='Building Owner'>{owner.label}</InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Street Address 1' className='col-span-2'>
              {planData?.lease.address.streetAddress1}
            </InfoField>
            {planData?.lease.address.streetAddress2 && (
              <InfoField title='Street Address 2' className='col-span-2'>
                {planData?.lease.address.streetAddress2}
              </InfoField>
            )}
            <InfoField title='City'>{planData?.lease.address.city}</InfoField>
            {planData?.lease.address.state && (
              <InfoField title='State'>
                {planData?.lease.address.state}
              </InfoField>
            )}
            <InfoField title='Postal Code'>
              {planData?.lease.address.postalCode}
            </InfoField>
            <InfoField title='Country'>
              {planData?.lease.address.country}
            </InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Start Date'>
              {formatDateToHumanDate(planData?.lease.startDate)}
            </InfoField>
            <InfoField title='End Date'>
              {formatDateToHumanDate(planData?.lease.endDate)}
            </InfoField>
            <InfoField title='Monthly Rent'>
              ${planData?.lease?.monthlyRent} {planData?.lease.currency}
            </InfoField>
            <InfoField title='Currency'>{planData?.lease?.currency}</InfoField>
          </InfoGrid>
          <InfoGrid>
            <InfoField title='Lease Verification'>
              <StatusLabel status={planData?.leaseVerification} />
            </InfoField>
            {planData?.lease?.document && (
              <InfoField title='Uploaded Lease' className='col-span-3'>
                <DocumentPreview
                  label={planData?.lease?.document}
                  url={planData?.lease?.document}
                />
              </InfoField>
            )}
          </InfoGrid>
        </InfoCard>
      </div>
    </DashboardWrapperLayout>
  );
};

export default PlanDetails;
