import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const miscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    requestManagerForPlan: (state, { payload }) => {
      console.log('Yes I was called?', payload);
      return {
        ...state,
        planId: payload.planId,
      };
    },
    resetManagerForPlan: () => initialState,
  },
});

// ACTIONS
export const { requestManagerForPlan, resetManagerForPlan } = miscSlice.actions;

// SELECTORS
export const selectRequestManagerForPlan = (state) => state.misc.planId;

// REDUCERS
export default miscSlice.reducer;
