import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IoMdSave } from 'react-icons/io';
import { toast } from 'react-toastify';
import ErrorData from '../../../components/shared/error-data';
import LoadingSpinner from '../../../components/shared/loading-spinner';
import * as Yup from 'yup';
import Input from '../../../components/shared/input';
import DashboardWrapperLayout from '../../../layouts/dashboard-wrapper';
import {
  useGetOwnerQuery,
  useUploadRequestUrlMutation,
  useUpdateOwnerMutation,
  useCreateOwnerMutation,
} from '../../../redux/api/ciosAdminApi/ciosAdminApi';
import { Form, Formik } from 'formik';
import SelectInput from '../../../components/shared/select-input';
import { ownerStatusEnum, documentStatusEnum } from '../../../utils/enums';
import { useNavigate } from 'react-router-dom';
import FormError from '../../../components/shared/form-error';
import { ownerStatusOptions } from '../../../utils/selectOptions';
import Button from 'common/components/button';

function View() {
  const { ownerId } = useParams();
  const navigate = useNavigate();
  const isNew = !ownerId;

  const [
    createOwner,
    { isLoading: isCreateLoading, isFetching: isCreateFetching },
  ] = useCreateOwnerMutation();
  const [
    updateOwner,
    { isLoading: isUpdateLoading, isFetching: isUpdateFetching },
  ] = useUpdateOwnerMutation();
  const {
    isError: isGetError,
    error: getError,
    data: ownerData,
  } = useGetOwnerQuery(ownerId, { skip: !ownerId });

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }
  if (!isNew && !ownerData) return <LoadingSpinner />;

  return (
    <Formik
      onSubmit={(value) => {
        if (isNew) {
          createOwner(value)
            .unwrap()
            .then((data) => {
              console.log(data);
              navigate(`/dashboard/owners/${data?.id}`);
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Create Failed');
            });
        } else {
          updateOwner({ ...value, id: ownerData.id })
            .unwrap()
            .then(() => {
              navigate(-1);
            })
            .catch((e) => {
              toast.error(e?.data?.msg || 'Update Failed');
            });
        }
      }}
      initialValues={{
        name: ownerData?.name || '',
        status: ownerData?.status || ownerStatusEnum.NEW,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
      })}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        isValid,
        values,
        dirty: isDirty,
        errors,
        setFieldValue,
      }) => {
        return (
          <Form>
            <DashboardWrapperLayout title='Owners'>
              {(isUpdateLoading || isUpdateFetching || isCreateFetching) && (
                <LoadingSpinner center={true} />
              )}
              <div className='flex flex-col w-full gap-6'>
                {/* breadcrumb */}
                <div className='flex gap-3 items-center'>
                  <Link
                    to='/dashboard/home'
                    className='font-normal text-base text-[#CED0CE]'
                  >
                    Dashboard
                  </Link>
                  <span className='font-normal text-base text-[#CED0CE]'>
                    {'>'}
                  </span>
                  <Link
                    to='/dashboard/owners'
                    className='text-[#CED0CE] text-base font-normal'
                  >
                    Owners{' '}
                  </Link>
                  {isNew && (
                    <>
                      <span className='font-normal text-base text-[#CED0CE]'>
                        {'>'}
                      </span>
                      <Link
                        to={''}
                        className='text-[#191923] text-base font-normal'
                      >
                        New{' '}
                      </Link>
                    </>
                  )}
                  {!isNew && (
                    <>
                      <span className='font-normal text-base text-[#CED0CE]'>
                        {'>'}
                      </span>
                      <Link
                        to={`/dashboard/owners/${ownerId}`}
                        className='text-[#CED0CE] text-base font-normal'
                      >
                        Details{' '}
                      </Link>
                      <span className='font-normal text-base text-[#CED0CE]'>
                        {'>'}
                      </span>
                      <Link
                        to=''
                        className='text-[#191923] text-base font-normal'
                      >
                        Edit{' '}
                      </Link>
                    </>
                  )}
                </div>

                {/* Owner Edit Table */}
                <div className='w-full rounded-[10px] gap-5 border border-gray'>
                  {/* header */}
                  <div className='flex w-full items-center bg-[#E1E6EF] text-[#191923] text-base font-bold px-5 py-5 rounded-t-[10px]'>
                    {`Owner's Information`}
                  </div>

                  {/* body content */}
                  <div className='flex w-full flex-col gap-6 px-2 py-2 border'>
                    {/* Details */}
                    <div className='grid w-full grid-cols-1 gap-y-10 gap-x-6 p-5'>
                      {/* Name */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Name
                          </label>
                          <Input
                            placeholderText='Name'
                            value={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='name'
                          />
                          <FormError name='name' />
                        </div>
                      </div>

                      {/* Status Select */}
                      {!isNew && (
                        <div className='flex flex-col gap-5'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Change status
                          </label>

                          <SelectInput
                            options={ownerStatusOptions}
                            placeholder='Select Status'
                            name='values.status'
                            value={ownerStatusOptions.find(
                              (status) => status.value === values.status
                            )}
                            onChange={(selectedOption) => {
                              setFieldValue(
                                'status',
                                selectedOption?.value || ''
                              );
                            }}
                            onBlur={handleBlur}
                          />
                        </div>
                      )}

                      <div className='flex'>
                        <div className='min-width-[100px] max-width-[200px]'>
                          <Button
                            disabled={!isValid || !isDirty}
                            Icon={IoMdSave}
                            onClick={handleSubmit}
                            title='Save Owner Data'
                          />
                        </div>
                      </div>
                    </div>
                    {/* Name */}
                  </div>
                </div>
              </div>
            </DashboardWrapperLayout>
          </Form>
        );
      }}
    </Formik>
  );
}

export default View;
