import React from 'react';
import { ToastContainer, Zoom } from 'react-toastify';
import { ChakraProvider } from '@chakra-ui/react';
import 'react-toastify/dist/ReactToastify.css';
import AppRouter from './routing/router';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import LoadingPageLayout from './layouts/loading-page';

let persistor = persistStore(store);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingPageLayout />} persistor={persistor}>
        <ChakraProvider>
          <AppRouter />
        </ChakraProvider>
      </PersistGate>
      
      {/* toast container */}
      <ToastContainer
        position='top-right'
        autoClose={3000}
        newestOnTop
        limit={1}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        transition={Zoom}
        pauseOnHover
        draggablePercent={40}
        theme='colored'
      />
    </Provider>
  );
}

export default App;
