import { AddressAutofill } from '@mapbox/search-js-react';
import { useState, useEffect, useRef } from 'react';

function formatAddress(address) {
  if (!address) {
    return '';
  }
  const { streetAddress1, streetAddress2, city, state, country, zipCode } =
    address;
  return `${
    streetAddress2 ? streetAddress2 + '-' : ''
  }${streetAddress1}, ${city}, ${state}, ${country} ${zipCode}`;
}

function extractMapBoxAddress(mapBox) {
  const o = mapBox.features?.[0]?.properties;
  if (o) {
    const { postcode, country, address_level1, address_level2, address_line1 } =
      o;
    return {
      streetAddress1: address_line1,
      city: address_level2,
      state: address_level1,
      zipCode: postcode,
      country,
    };
  } else {
    return null;
  }
}

function AutoCompleteAddress({ initialAddress, onAddressChange, placeholder }) {
  const inputRef = useRef();
  // This is the values that we extract from mapbox when it suggests an address
  // see the extractFromMapBox function for its format
  // We track this internally in this state
  // If we have a new address, we will pass it to onAddressChange, which can handle model updates
  const [address, setAddress] = useState(initialAddress);

  // This is the actual string that will exist in the auto complete text box
  const [strAddress, setStrAddress] = useState(formatAddress(initialAddress));

  // This is a bit hacky, but in order to make the full address appear in a single textbox,
  // have to check for all these conditions.
  // Can't rely on onRetrieve, since it fires before mapbox changes the input value of the textbox
  useEffect(() => {
    if (
      inputRef.current &&
      address &&
      strAddress !== formatAddress(address) &&
      !address.setInputValue
    ) {
      setAddress({ ...address, setInputValue: true });
      setStrAddress(formatAddress(address));
    }
  }, [strAddress, address]);

  return (
    <AddressAutofill
      ref={inputRef}
      browserAutofillEnabled={false}
      accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      onRetrieve={(args) => {
        const newAddress = extractMapBoxAddress(args);
        if (newAddress) {
          onAddressChange(newAddress);
          setAddress({ ...newAddress, setInputValue: false });
        }
      }}
    >
      <input
        className='rounded-md border-[#CED0CE] border px-3 h-10 grow w-full'
        placeholder={placeholder || 'Enter Your Address'}
        type='text'
        value={strAddress}
        onChange={(e) => {
          setStrAddress(e.target.value);
        }}
        autoComplete='address-line1'
      />
    </AddressAutofill>
  );
}

export default AutoCompleteAddress;
