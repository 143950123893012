import { useState } from 'react';
import { Link } from 'react-router-dom';
import ErrorData from '../../../components/shared/error-data';
import ManagersTable from '../../../components/managers/managers-table';
import EmptyData from '../../../components/shared/empty-data';
import DashboardWrapperLayout from '../../../layouts/dashboard-wrapper';
import TablePaginateWrapper from '../../../layouts/table-paginate-wrapper';
import ManagerPlanSelect from '../../../components/shared/manager-plan-select';
import LoadingSpinner from '../../../components/shared/loading-spinner';
import { isEmpty } from '../../../utils/isEmpty';
import { useFindManagersQuery } from '../../../redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import Button from 'common/components/button';

const View = ({ statuses }) => {
  const pageSize = 10;

  const navigate = useNavigate();

  // STATES
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: managersQuery,
    isError: isGetError,
    error: getError,
    isLoading: findManagersLoading,
  } = useFindManagersQuery({
    limit: pageSize,
    page: currentPage,
    status: statuses,
  });

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }

  const { data: managersData, meta: managersMeta } = managersQuery || {
    data: [],
    meta: { count: 0 },
  };
  console.log('All managers?', managersData);

  // HOOKS

  return (
    <DashboardWrapperLayout title='Managers'>
      <ManagerPlanSelect />
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Dashboard
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link
            to='/dashboard/managers'
            className='text-[#191923] text-base font-normal'
          >
            Managers{' '}
          </Link>
        </div>

        <Button
          onClick={() => {
            navigate('/dashboard/managers/new');
          }}
          title='New Manager'
        />
        {/* table */}
        {findManagersLoading && <LoadingSpinner center={true} />}
        {isEmpty(managersData) ? (
          <EmptyData />
        ) : (
          <TablePaginateWrapper
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalCount={managersMeta.count}
          >
            <ManagersTable managers={managersData} />
          </TablePaginateWrapper>
        )}
      </div>
    </DashboardWrapperLayout>
  );
};

export default View;
