import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useFindOwnersQuery } from '../../../redux/api/ciosAdminApi/ciosAdminApi';
import OwnersTable from '../../../components/owners/table';
import ErrorData from '../../../components/shared/error-data';
import Button from 'common/components/button';
import DashboardWrapperLayout from '../../../layouts/dashboard-wrapper';
import TablePaginateWrapper from '../../../layouts/table-paginate-wrapper';
import LoadingSpinner from '../../../components/shared/loading-spinner';
import { isEmpty } from '../../../utils/isEmpty';
import EmptyData from '../../../components/shared/empty-data';

function View() {
  const navigate = useNavigate();
  // DATA INITIALIZATION
  const pageSize = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: ownersQuery,
    isError: isGetError,
    error: getError,
    isLoading: findOwnersLoading,
  } = useFindOwnersQuery({
    limit: pageSize,
    page: currentPage,
  });

  const { data: ownersData, meta: ownersMeta } = ownersQuery || {
    data: [],
    meta: { count: 0 },
  };

  if (isGetError) {
    return <ErrorData message={getError?.data?.msg} />;
  }
  console.log('All owners?', ownersData);
  return (
    <DashboardWrapperLayout title='Owners'>
      <div className='flex flex-col w-full gap-6'>
        {/* breadcrumb */}
        <div className='flex gap-3 items-center'>
          <Link
            to='/dashboard/home'
            className='font-normal text-base text-[#CED0CE]'
          >
            Dashboard
          </Link>
          <span className='font-normal text-base text-[#CED0CE]'>{'>'}</span>
          <Link to='' className='text-[#191923] text-base font-normal'>
            Owners{' '}
          </Link>
        </div>

        <div>
          <Button
            title='Add Owner'
            onClick={() => navigate(`/dashboard/owners/new`)}
          ></Button>
        </div>

        {/* table */}
        {findOwnersLoading && <LoadingSpinner center={true} />}
        {isEmpty(ownersData) ? (
          <EmptyData />
        ) : (
          <TablePaginateWrapper
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalCount={ownersMeta.count}
          >
            <OwnersTable owners={ownersData} />
          </TablePaginateWrapper>
        )}
      </div>
    </DashboardWrapperLayout>
  )
}

export default View
