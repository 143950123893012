import routes from './routes';

const defaultFetchFn = (...args) => fetch(...args);

async function devfetch(...args) {
  // if matches one of the routes, call its handler, otherwise, call the default
  const req = args[0].clone();
  for (const route of routes) {
    if (route.match(req)) {
      console.log('Calling the handler?', req);
      return route.handle(req);
    }
  }

  const queryParams = new window.URLSearchParams(window.location.search);
  const delayApi =
    queryParams.get('_delayApi') || window.localStorage.getItem('_delayApi');

  if (delayApi) {
    await new Promise((resolve) => setTimeout(resolve, Number(delayApi)));
  }

  return defaultFetchFn(...args);
}

export default devfetch;
