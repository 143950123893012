import { useState } from 'react';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FiLock } from 'react-icons/fi';

const PasswordInput = ({
  placeholderText = '',
  name = '',
  onChange,
  value,
  onFocus,
  onBlur,
  disabled,
}) => {
  // STATES
  const [isHidden, setIsHidden] = useState(true);
  return (
    <div className='flex max-w-lg items-center w-full border border-[#CED0CE] rounded-md h-10 px-3 gap-x-3'>
      {/* icon */}
      <FiLock className='text-lg text-[#6A6C70]' />

      {/* input */}
      <input
        placeholder={placeholderText}
        name={name}
        value={value}
        type={isHidden ? 'password' : 'text'}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        className='h-full w-full border-none [outline:none] text-base text-[#191923] font-normal placeholder:text-[#6A6C70] placeholder:font-normal placeholder:text-base'
      />

      {/* password ishidden indicator */}
      {isHidden ? (
        <AiOutlineEyeInvisible
          onClick={() => setIsHidden(!isHidden)}
          className='text-lg text-[#6A6C70] cursor-pointer'
        />
      ) : (
        <AiOutlineEye
          onClick={() => setIsHidden(!isHidden)}
          className='text-lg text-[#6A6C70] cursor-pointer'
        />
      )}
    </div>
  );
};

export default PasswordInput;
