import React from 'react';
import Pagination from 'components/shared/pagination';

const TablePaginateWrapper = ({
  children,
  setCurrentPage,
  totalCount,
  pageSize,
  currentPage,
}) => {
  return (
    <div className='w-full flex flex-col gap-7'>
      {children}

      {/* pagination */}
      <Pagination
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        currentPage={currentPage}
        totalCount={totalCount}
      />
    </div>
  );
};

export default TablePaginateWrapper;
