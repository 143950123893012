function deepGet(obj, path) {
  const keys = path.split(/\.|\[|\]./g).filter((key) => key.length > 0);
  let value = obj;

  for (const key of keys) {
    if (value && typeof value === 'object') {
      value = value[key];
    } else {
      value = undefined;
      break;
    }
  }

  return value;
}

export default deepGet;
