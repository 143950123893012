import React from 'react';
import { RiErrorWarningFill } from 'react-icons/ri';

const EmptyData = ({ message = 'Nothing to see here!' }) => {
  return (
    <div className='w-full min-h-[50dvh] flex items-center justify-center text-2xl font-semibold text-gray-400'>
      <div>
        <RiErrorWarningFill />
      </div>
      <div>{message}</div>
    </div>
  );
};

export default EmptyData;
