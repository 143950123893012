import { Link, useParams } from 'react-router-dom';
import { IoMdSave } from 'react-icons/io';
import * as Yup from 'yup';
import DashboardWrapperLayout from '../../../layouts/dashboard-wrapper';
import { Form, Formik, useFormikContext } from 'formik';
import deepGet from '../../../utils/deepGet';
import AutoCompleteAddress from '../../../components/shared/autoaddress';
import Input from '../../../components/shared/input';
import SelectInput from '../../../components/shared/select-input';
import { rentalTypeEnum } from '../../../utils/enums';
import FormError from '../../../components/shared/form-error';
import { useNewPropertyMutation } from '../../../redux/api/ciosAdminApi/ciosAdminApi';
import { useNavigate } from 'react-router-dom';
import Button from 'common/components/button';

const rentalTypeOptions = [
  { label: 'Apartment', value: rentalTypeEnum.APARTMENT },
  { label: 'Condo', value: rentalTypeEnum.CONDO },
  { label: 'House', value: rentalTypeEnum.HOUSE },
  { label: 'Townhouse', value: rentalTypeEnum.TOWNHOUSE },
  { label: 'Basement', value: rentalTypeEnum.BASEMENT },
  { label: 'Room', value: rentalTypeEnum.ROOM },
];

function AutoCompleteAddressFormikWrapper({ name, placeholder }) {
  const { values, setFieldValue } = useFormikContext();
  const pathToAddress = `${name}`;

  const initialAddress = deepGet(values, pathToAddress);
  const handleAddressChange = (newAddress) => {
    setFieldValue(pathToAddress, newAddress);
  };

  return (
    <AutoCompleteAddress
      initialAddress={initialAddress}
      onAddressChange={handleAddressChange}
      placeholder={placeholder}
    />
  );
}

function View() {
  const { managerId } = useParams();
  const navigate = useNavigate();
  const [newProperty, { isLoading: isLoadingNewProperty }] =
    useNewPropertyMutation();
  return (
    <Formik
      onSubmit={(values) => {
        newProperty({
          ...values,
          manager: managerId,
        }).then(() => {
          navigate(`/dashboard/managers/${managerId}`);
        });
      }}
      initialValues={{}}
      validationSchema={Yup.object().shape({
        buildingName: Yup.string().required('Building name is required'),
        minCreditScore: Yup.number()
          .required('Minimum credit score is required')
          .min(300, 'Minimum credit score is 300')
          .max(1000, 'Maximum credit score is 1000'),
        minIncome: Yup.number()
          .required('Minimum income is required')
          .min(0, 'Minimum income must be a non-negative number'),
        neighborhood: Yup.string().required('Neighborhood is required'),
        rentalType: Yup.string().required('Rental type is required'),
        monthlyRent: Yup.number()
          .required('Monthly rent is required')
          .min(0, 'Monthly rent must be a non-negative number'),
        terminationFee: Yup.number()
          .required('Termination fee is required')
          .min(0, 'Termination fee must be a non-negative number'),
      })}
    >
      {({
        handleSubmit,
        handleBlur,
        handleChange,
        isValid,
        values,
        setFieldValue,
      }) => {
        return (
          <Form>
            <DashboardWrapperLayout title='Managers'>
              <div className='flex flex-col w-full gap-6'>
                {/* breadcrumb */}
                <div className='flex gap-3 items-center'>
                  <Link
                    to='/dashboard/home'
                    className='font-normal text-base text-[#CED0CE]'
                  >
                    Dashboard
                  </Link>
                  <span className='font-normal text-base text-[#CED0CE]'>
                    {'>'}
                  </span>
                  <Link
                    to='/dashboard/managers'
                    className='text-[#CED0CE] text-base font-normal'
                  >
                    Managers{' '}
                  </Link>
                  <span className='font-normal text-base text-[#CED0CE]'>
                    {'>'}
                  </span>
                  <Link
                    to={`/dashboard/managers/${managerId}`}
                    className='text-[#CED0CE] text-base font-normal'
                  >
                    {managerId}
                  </Link>
                  <span className='font-normal text-base text-[#CED0CE]'>
                    {'>'}
                  </span>
                  <Link to='' className='text-[#191923] text-base font-normal'>
                    New Property{' '}
                  </Link>
                </div>

                <div className='w-full rounded-[10px] gap-5'>
                  {/* header */}
                  <div className='flex w-full items-center bg-[#E1E6EF] text-[#191923] text-base font-bold px-5 py-5 rounded-t-[10px]'>
                    {`Properties Information`}
                  </div>
                  {/* Content*/}
                  <div className='flex w-full flex-col gap-6 px-2 py-2 border'>
                    {/* Manager Details */}
                    <div className='grid w-full grid-cols-1 gap-y-10 gap-x-6 p-5'>
                      <div className='w-full flex items-center gap-5'>
                        {/* Building Name */}
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Building Name
                          </label>
                          <Input
                            placeholderText='Building Name'
                            value={values.buildingName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`buildingName`}
                          />
                          <FormError name={`buildingName`} />
                        </div>
                      </div>
                      {/* Minimum Credit Score */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Minimum Credit Score
                          </label>
                          <Input
                            placeholderText='Minimum Credit Score'
                            value={values.minCreditScore}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`minCreditScore`}
                          />
                          <FormError name={`minCreditScore`} />
                        </div>
                      </div>

                      {/* Minimum Income */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Minimum Income
                          </label>
                          <Input
                            placeholderText='Minimum Income'
                            value={values.minIncome}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`minIncome`}
                          />
                          <FormError name={`minIncome`} />
                        </div>
                      </div>

                      {/* Neighborhood */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Neighborhood
                          </label>
                          <Input
                            placeholderText='Neighborhood'
                            value={values.neighborhood}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`neighborhood`}
                          />
                          <FormError name={`neighborhood`} />
                        </div>
                      </div>

                      {/* Rental Type */}
                      <div className='flex flex-col gap-5'>
                        <label className='text-[#191923] text-sm font-bold'>
                          Rental Type
                        </label>

                        <SelectInput
                          options={rentalTypeOptions}
                          placeholder='Select Type'
                          name={`rentalType`}
                          value={rentalTypeOptions.find(
                            (rentalType) =>
                              rentalType.value === values.rentalType
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              `rentalType`,
                              selectedOption?.value || ''
                            );
                          }}
                          onBlur={handleBlur}
                        />
                      </div>

                      <FormError name={`rentalType`} />

                      {/* Monthly Rent */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Monthly Rent
                          </label>
                          <Input
                            placeholderText='Monthly Rent'
                            value={values.monthlyRent}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`monthlyRent`}
                          />
                          <FormError name={`monthlyRent`} />
                        </div>
                      </div>

                      {/* Termination Fee */}
                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Termination Fee
                          </label>
                          <Input
                            placeholderText='Termination Fee'
                            value={values.terminationFee}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name={`terminationFee`}
                          />
                          <FormError name={`terminationFee`} />
                        </div>
                      </div>

                      <div className='w-full flex items-center gap-5'>
                        <div className='w-full flex flex-col gap-2'>
                          <label className='text-[#191923] text-sm font-bold'>
                            Address
                          </label>
                          <AutoCompleteAddressFormikWrapper
                            name={`address`}
                            placeholder='Enter Address'
                          />
                        </div>
                      </div>
                      <div className='flex'>
                        <div className='min-width-[100px] max-width-[200px]'>
                          <Button
                            Icon={IoMdSave}
                            disabled={!isValid || isLoadingNewProperty}
                            onClick={handleSubmit}
                            title='Save Property Data'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DashboardWrapperLayout>
          </Form>
        );
      }}
    </Formik>
  );
}

export default View;
